import {
  Textarea as HeadlessTextarea,
  TextareaProps as HeadlessTextareaProps,
} from '@headlessui/react';
import React, { ElementRef, FC, forwardRef, ReactNode, useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { RedInfoCircle } from '../../assets/icons';
import { useEventOutsideElement } from '../../hooks/useEventOutsideElement';
import { useForwardRef } from '../../hooks/useForwardRef';
import { InputStatus } from '../Combobox';
import {
  textareaStatusToPlaceholderClassNamesMap,
  textareaStatusToWrapperClassNamesMap,
} from './constants';

enum TextareaStyleVariant {
  DEFAULT = 'mt-3 block min-h-20 w-full resize-none rounded-lg border-none bg-white p-3 pt-4 text-xs font-[450] text-gray-600 ring-1 ring-gray-200 placeholder:text-xs placeholder:font-[450] placeholder:text-gray-300 focus:ring-brand-600 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
  DEFAULT_W_PLACEHOLDER = 'min-h-10 bg-gray-50',
  LIGHT_W_PLACEHOLDER = 'min-h-10 bg-gray-50 placeholder:text-gray-300 resize-none rounded-lg',
}

export type TextareaProps = {
  styleVariant?: keyof typeof TextareaStyleVariant;
  placeholder?: ReactNode;
  errorMessage?: string | false;
  wrapperClassName?: string;
  maxLength?: number;
  textAreaClassName?: string;
  placeholderClassName?: string;
  isShownCharCount?: boolean;
} & Except<HeadlessTextareaProps, 'placeholder'>;

const Textarea: FC<TextareaProps> = forwardRef<ElementRef<typeof HeadlessTextarea>, TextareaProps>(
  (
    {
      className,
      value,
      onChange,
      styleVariant = 'DEFAULT',
      placeholder,
      errorMessage,
      wrapperClassName,
      maxLength = 3000,
      textAreaClassName,
      placeholderClassName,
      isShownCharCount = true,
      ...props
    },
    ref,
  ) => {
    const textareaRef = useForwardRef<HTMLElement>(ref);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isFocused, setFocused] = useState(false);
    const [status, setStatus] = useState(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
    const [charCount, setCharCount] = useState(value ? value.toString().length : 0);

    useEventOutsideElement(wrapperRef, 'click', () => {
      setFocused(!!charCount);
      setStatus(charCount ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
    });

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event);
        setStatus(event.target.value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
        setCharCount(event.target.value.length);
      },
      [onChange],
    );

    const handleFocus = useCallback(() => {
      setFocused(true);
      setStatus(InputStatus.FOCUSED);
    }, []);

    const handleBlur = useCallback(() => {
      if (!charCount) return setFocused(false);
    }, [charCount]);

    const handleClick = useCallback(() => {
      textareaRef.current?.focus();
    }, [textareaRef]);

    return (
      <div
        className={twMerge(
          'flex min-h-14 cursor-text flex-col gap-3 disabled:cursor-default',

          wrapperClassName,
        )}
        onClick={handleClick}
        ref={wrapperRef}
      >
        <div
          className={twMerge(
            `relative flex flex-col items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${textareaStatusToWrapperClassNamesMap[status]}`,

            textAreaClassName,
          )}
        >
          {placeholder && (
            <div
              className={twMerge(
                `pointer-events-none absolute left-2.5 top-[25px] -translate-y-[10px] font-inter text-sm font-[450] transition-all duration-75 ${textareaStatusToPlaceholderClassNamesMap[status]} ${isFocused || value ? '!top-[8px] !-translate-y-1 !text-xs' : ''}`,
                placeholderClassName,
              )}
            >
              {placeholder}
            </div>
          )}
          <div className="flex h-full w-full flex-col justify-end pt-6 text-sm">
            <HeadlessTextarea
              className={(props) =>
                twMerge(
                  TextareaStyleVariant[styleVariant],
                  typeof className === 'string' ? className : className?.(props),
                )
              }
              maxLength={maxLength}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              ref={textareaRef}
              value={value}
              {...props}
            />
          </div>
          {isShownCharCount && (
            <div className="flex w-full justify-end pb-2 text-right text-label-sm font-[450] text-gray-400">
              {charCount}/{maxLength}
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="flex items-center gap-1">
            <RedInfoCircle />
            <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
              {errorMessage}
            </div>
          </div>
        )}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
