import { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { CrossIcon, SuccessIcon } from '../../assets/icons';
import Button from '../../components/Button';
import FormInput from '../../components/Input/FormInput';
import PhoneNumberCombobox from '../../components/PhoneNumberCombobox/PhoneNumberCombobox';
import { FormTextarea } from '../../components/Textarea';
import { useReactForm } from '../../hooks/useForm';
import { TopicCombobox } from './TopicCombobox';
import { FormSchema, formSchema, Topic } from './validation';

interface ContactSalesDto {
  firstName: string;
  lastName: string;
  email: string;
  date: string;
  companyName: string;
  topic: Topic;
  message: string;
  phone: string | undefined;
  phonePrefix: string | undefined;
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const ContactForm: FC = () => {
  const [showSuccessBlock, setShowSuccessBlock] = useState<boolean>(false);
  const [showErrorBlock, setShowErrorBlock] = useState<boolean>(false);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
  });

  const { mutate, isLoading } = useMutation(
    async (contactSalesDto: ContactSalesDto) => {
      const response = await fetch(`${backendUrl}/admin-panel/contact-sales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactSalesDto),
      });

      if (!response.ok) {
        throw new Error('Failed to send contact sales email');
      }
    },
    {
      onSuccess: () => {
        setShowSuccessBlock(true);
        reset();
      },
      onError: (error) => {
        console.error('Error sending contact sales email:', error);
        setShowErrorBlock(true);
      },
    },
  );

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { companyName, email, firstName, lastName, message, phoneNumber, topic, phoneCountry } =
        data;
      const contactSalesDto = {
        firstName,
        lastName,
        email,
        date: new Date().toISOString(),
        companyName,
        topic,
        message,
        phone: phoneNumber || undefined,
        phonePrefix: phoneCountry?.prefix || undefined,
      };

      mutate(contactSalesDto);
    },
    [mutate],
  );

  const selectedPhoneNumber = watch('phoneNumber');
  const selectedPhoneCountry = watch('phoneCountry');

  return (
    <>
      {showSuccessBlock ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[56px] px-[108px] py-10">
          <div className="flex flex-col items-center gap-6">
            <SuccessIcon />
            <span className="max-w-[180px] text-center text-base text-forest-600">
              Your request has been sent successfully.
            </span>
          </div>
          <Button
            className="flex w-full cursor-pointer items-center justify-center text-nowrap rounded border-[1px] border-gray-500 bg-transparent px-4 py-[14px] font-inter text-sm font-[550] text-gray-500 transition-all duration-300 hover:bg-opacity-85"
            onClick={() => setShowSuccessBlock(false)}
            styleType="NONE"
          >
            Send another
          </Button>
        </div>
      ) : showErrorBlock ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[56px] px-[108px] py-10">
          <div className="flex flex-col items-center gap-6">
            <div className="flex size-[72px] items-center justify-center rounded-full bg-fireside-600">
              <CrossIcon className="size-14" iconColor="#ffffff" />
            </div>

            <span className="max-w-[180px] text-center text-base text-fireside-600">
              An error occurred. Please try again.
            </span>
          </div>
          <Button
            className="flex w-full cursor-pointer items-center justify-center text-nowrap rounded border-[1px] border-gray-500 bg-transparent px-4 py-[14px] font-inter text-sm font-[550] text-gray-500 transition-all duration-300 hover:bg-opacity-85"
            onClick={() => setShowErrorBlock(false)}
            styleType="NONE"
          >
            Send another
          </Button>
        </div>
      ) : (
        <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit(submitHandler)}>
          <div className="flex gap-5">
            <FormInput control={control} name="firstName" placeholder="First Name" />
            <FormInput control={control} name="lastName" placeholder="Last Name" />
          </div>

          <FormInput control={control} name="email" placeholder="Email" />
          <PhoneNumberCombobox
            onPhoneChange={(phoneNumber) =>
              setValue('phoneNumber', phoneNumber, {
                shouldValidate: true,
              })
            }
            onSelectCountry={(country) => setValue('phoneCountry', country)}
            selectedPhoneNumber={{
              number: selectedPhoneNumber || '',
              country: selectedPhoneCountry,
            }}
          />
          <FormInput control={control} name="companyName" placeholder="Company name" />
          <TopicCombobox control={control} />
          <FormTextarea
            control={control}
            name="message"
            placeholder="What can we help you with?"
            styleVariant="LIGHT_W_PLACEHOLDER"
          />
          <Button disabled={!isValid} isLoading={isLoading} styleType="BLUE_ROUNDED">
            Send
          </Button>
        </form>
      )}
    </>
  );
};
