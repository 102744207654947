import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ArrowRightIconProps = IconProps;

const ArrowRightIcon: FC<ArrowRightIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 8.00016H12.6668M12.6668 8.00016L8.00016 3.3335M12.6668 8.00016L8.00016 12.6668"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export default ArrowRightIcon;
