import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import CircleLoader from '../CircleLoader';

enum ButtonStyleVariant {
  BLUE_ROUNDED = 'bg-brand-700 px-8 py-[14px] text-white font-inter font-[500] text-sm  disabled:ring-1 disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-300 hover:bg-[#146EF5]',
  DEFAULT_ROUNDED = 'bg-transparent px-4 py-[14px] text-gray-900 font-inter font-[500] text-sm  disabled:ring-1 disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-300 hover:bg-[white]',
  NONE = '',
}
export type ButtonsProps = {
  children?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  className?: string;
  styleType?: keyof typeof ButtonStyleVariant;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: FC<ButtonsProps> = ({
  children,
  disabled,
  onClick,
  isLoading,
  className,
  styleType,
}) => {
  return (
    <button
      className={twMerge(
        'flex w-full cursor-pointer items-center justify-center text-nowrap rounded transition-all duration-300 hover:bg-opacity-85 disabled:cursor-default disabled:bg-gray-100 disabled:text-gray-300 disabled:ring-1 disabled:ring-gray-200',
        styleType && ButtonStyleVariant[styleType],
        className,
      )}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? <CircleLoader /> : children}
    </button>
  );
};

export default Button;
