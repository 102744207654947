import React from 'react';

const TwitterIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_389_6325)">
        <path
          clipRule="evenodd"
          d="M10.6303 15.3337L6.9307 10.0604L2.29924 15.3337H0.339844L6.06139 8.82105L0.339844 0.666992H5.37048L8.85732 5.637L13.2262 0.666992H15.1856L9.72955 6.87798L15.6609 15.3337H10.6303ZM12.8123 13.847H11.4932L3.14541 2.15366H4.46474L7.80808 6.83577L8.38623 7.64824L12.8123 13.847Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_389_6325">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
