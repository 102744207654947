import { useScroll } from 'framer-motion';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon, CalculatorIcon, CheckCircleIcon, StarsIcon } from '../../assets/icons';
import blueNumbers from '../../assets/images/background/blue-numbers.png';
import grayLines from '../../assets/images/background/gray-lines.png';
import Button from '../../components/Button';
import FinalSection from '../../components/FinalSection/FinalSection';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { FeatureCard } from './FeatureCard';
import { Solution } from './types';

export const SolutionsConstructor: FC<Solution> = ({
  description,
  features: { list: features, description: featuresDescription, title: featuresTitle },
  title,
  img,
  benefits: [benefit1, benefit2, benefit3],
}) => {
  const navigate = useNavigate();
  // const redirectToAuth = () =>
  //   window.location.replace(process.env.REACT_APP_CAP_QUEST_REGISTER_URL || '');
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  return (
    <div className="relative text-center font-inter text-sm font-normal text-gray-700">
      <main className="flex flex-col items-center">
        <section className="flex w-full flex-col bg-[#F2F4F7] px-4">
          <Header className="bg-transparent" />
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="h-[90px] w-[336px]">
              <img className="object-cover" src={grayLines} />
            </div>
            <span className="w-fit rounded-full border border-[#98A2B3] px-3 py-1 text-label-md font-medium text-[#667085]">
              Solutions
            </span>
            <span className="text-xxl font-semibold text-[#101828]">{title}</span>
            <span className="max-w-[600px] text-center text-sm text-[#475467]">{description}</span>

            <div className="relative z-10 flex w-full flex-col items-center justify-center gap-6 pt-10 xlg:flex-row">
              {/* <Button
                className="w-full xlg:w-auto"
                onClick={redirectToAuth}
                styleType="BLUE_ROUNDED"
              >
                Get Started
              </Button> */}
              <Button
                className="group w-full gap-[6px] xlg:w-auto"
                onClick={() => navigate('/contact-sales')}
                styleType="DEFAULT_ROUNDED"
              >
                Contact Sales
                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2">
                  <ArrowRightIcon />
                </span>
              </Button>
            </div>
          </div>

          <div className="relative flex justify-center">
            <div
              className="absolute top-0 h-[167px] w-full max-w-[960px] transform bg-no-repeat"
              style={{
                backgroundImage: `url(${blueNumbers})`,
              }}
            />
            <div className="z-1 pt-[80px]">
              <img className="object-cover" src={img} />
            </div>
          </div>
        </section>
        {/* <section className="flex w-full flex-col gap-12 border-b border-[#1018280F] py-14 text-center">
          <span className="text-base text-gray-600">
            Trusted by leading companies and partners from around the world
          </span>
          <CompanySlider />
        </section> */}
        <section className="flex max-w-[1280px] flex-col gap-20 px-4 py-20 text-center">
          <div className="flex flex-col items-center gap-4 text-center">
            <span className="max-w-[600px] text-3xl font-semibold text-[#101828]">
              {featuresTitle}
            </span>
            <span className="text-base text-[#475467]">{featuresDescription}</span>
          </div>
          <div className="flex flex-col" ref={container} style={{ scrollBehavior: 'smooth' }}>
            {features.map((feature, i) => (
              <FeatureCard
                key={`${feature.title}_${i}`}
                progress={scrollYProgress}
                range={[i * 0.25, 1]}
                reversOrder={i % 2 !== 0}
                targetScale={1 - (features.length - i) * 0.05}
                {...feature}
              />
            ))}
          </div>
        </section>
        <section className="relative flex w-full flex-col items-center gap-16 overflow-hidden bg-[#020811] px-4 py-20 text-center">
          <div className="absolute top-0 size-[500px] -translate-y-1/2 bg-[#2565C8] opacity-30 blur-[368px]" />
          <div className="flex flex-col gap-4">
            <span className="text-3xl font-semibold text-white">Why you’ll love CapQuest</span>
            <span className="max-w-[550px] text-base text-[#C6C6C6]">
              Join the growing number of companies who trust CapQuest for their ESOP, Cap Table and
              ownership management
            </span>
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            <div className="flex h-[240px] w-[340px] flex-col items-center gap-4 rounded-xl bg-[#1D2939] px-6 py-8 text-center text-sm text-[#C6C6C6]">
              <CheckCircleIcon className="size-14" iconColor="#ffffff" />
              <span className="pt-4 text-[18px] font-semibold leading-[21px] text-white">
                {benefit1.title}
              </span>
              <span>{benefit1.description}</span>
            </div>
            <div className="flex h-[240px] w-[340px] flex-col items-center gap-4 rounded-xl bg-[#1D2939] px-6 py-8 text-center text-sm text-[#C6C6C6]">
              <CalculatorIcon className="size-14" iconColor="#ffffff" />
              <span className="pt-4 text-[18px] font-semibold leading-[21px] text-white">
                {benefit2.title}
              </span>
              <span>{benefit2.description}</span>
            </div>
            <div className="flex h-[240px] w-[340px] flex-col items-center gap-4 rounded-xl bg-[#1D2939] px-6 py-8 text-center text-sm text-[#C6C6C6]">
              <StarsIcon className="size-14" iconColor="#ffffff" />
              <span className="pt-4 text-[18px] font-semibold leading-[21px] text-white">
                {benefit3.title}
              </span>
              <span>{benefit3.description}</span>
            </div>
          </div>
        </section>
        <FinalSection />
      </main>
      <Footer />
    </div>
  );
};
