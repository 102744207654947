import React from 'react';

type GradientBorderBoxProp = {
  variant: 'REVIEWS' | 'TOOLS';
};

const GradientBorderBox: React.FC<GradientBorderBoxProp> = ({ variant }) => {
  return (
    <>
      {variant === 'REVIEWS' ? (
        <>
          <div
            className="absolute -left-[10px] h-[calc(100%-78px)] w-[calc(100%+34px)] -translate-x-2 transform border-b-[1px] border-t-[1px] lg:-left-[24px] lg:top-[24px] lg:h-[calc(100%-48px)] lg:w-[calc(100%+66px)]"
            style={{
              borderImageSource:
                'radial-gradient(73.06% 160316.53% at 47.86% 29.300%, rgba(255, 255, 255, 0.6) 0%, rgba(112, 115, 119, 0.2) 75%)',
              borderImageSlice: 1,
              borderStyle: 'solid',
            }}
          />
          <div className="absolute -left-[10px] h-[calc(100%-78px)] w-[calc(100%+34px)] -translate-x-2 transform border-b-[1px] border-t-[1px] border-dashed border-[#020811] lg:-left-[24px] lg:top-[24px] lg:h-[calc(100%-48px)] lg:w-[calc(100%+66px)]" />
          <div
            className="absolute left-[32px] top-[16px] h-[calc(100%-36px)] w-full -translate-x-8 transform border-l-[1px] border-r-[1px] lg:left-[24px] lg:top-0 lg:h-full lg:w-[calc(100%+18px)]"
            style={{
              borderImageSource:
                'radial-gradient(73.06% 160316.53% at 47.86% 29.300%, rgba(255, 255, 255, 0.6) 0%, rgba(112, 115, 119, 0.2) 75%)',
              borderImageSlice: 1,
              borderStyle: 'solid',
            }}
          />

          <div className="absolute left-[32px] top-[16px] h-[calc(100%-36px)] w-full -translate-x-8 transform border-l-[1px] border-r-[1px] border-dashed border-[#020811] lg:left-[24px] lg:top-0 lg:h-full lg:w-[calc(100%+18px)]" />
        </>
      ) : (
        <>
          <div
            className="absolute -left-[50px] top-0 z-1 h-full w-full transform border-b-[1px] border-t-[1px]"
            style={{
              width: 'calc(100% + 100px)',
              borderImageSource:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.3) -11.65%, rgba(0, 0, 0, 0.06) 49.98%, rgba(255, 255, 255, 0.3) 110.93%)',
              borderImageSlice: 1,
              borderStyle: 'solid',
            }}
          />
          <div
            className="absolute -left-[50px] top-0 h-full w-full transform border-b-[1px] border-t-[1px] border-dashed border-brand-50"
            style={{
              width: 'calc(100% + 100px)',
            }}
          />
          <div
            className="absolute -top-[50px] left-0 h-full w-full transform border-l-[1px] border-r-[1px]"
            style={{
              height: 'calc(100% + 100px)',
              borderImageSource:
                'linear-gradient(90deg, rgba(255, 255, 255, 0.3) -11.65%, rgba(0, 0, 0, 0.06) 49.98%, rgba(255, 255, 255, 0.3) 110.93%)',
              borderImageSlice: 1,
              borderStyle: 'solid',
            }}
          />
          <div
            className="absolute -top-[50px] left-0 h-full w-full transform border-l-[1px] border-r-[1px] border-dashed border-brand-50"
            style={{
              height: 'calc(100% + 100px)',
            }}
          />
        </>
      )}
    </>
  );
};

export default GradientBorderBox;
