import React, { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useElementOnScreen } from '../../hooks/useElementOnScreen';

export type Message = {
  text: string;
  title: string;
};

type AutoSwitchMessagesProps = {
  messages: Message[];
  side?: 'RIGHT' | 'LEFT';
  variant?: 'DARK' | 'LIGHT';
};

const AutoSwitchMessages: React.FC<AutoSwitchMessagesProps> = ({
  messages,
  side = 'LEFT',
  variant = 'LIGHT',
}) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const nextMessage = useCallback(() => {
    setCurrentMessage((prev) => (prev + 1) % messages.length);
    setProgress(0);
  }, [messages.length]);

  const handleClick = (index: number) => {
    setCurrentMessage(index);
    setProgress(0);
  };

  const elementOnScreen = useElementOnScreen({ ref });

  useEffect(() => {
    if (!elementOnScreen) return;

    const interval = setInterval(() => {
      if (progress === 100) {
        setIsCompleted(true);
        nextMessage();
      } else {
        setProgress((prev) => prev + 1);
      }
    }, 75);

    return () => clearInterval(interval);
  }, [progress, nextMessage, elementOnScreen]);

  useEffect(() => {
    setProgress(0);
    setIsCompleted(false);
  }, [currentMessage]);

  return (
    <div className="flex max-w-[524px] flex-col" ref={ref}>
      {messages.map((message, index) => (
        <div
          className={twMerge(
            'relative flex min-h-full flex-grow basis-[50%] cursor-pointer flex-col gap-4 py-4',
            side === 'RIGHT' ? 'items-end pr-8 text-right' : 'items-start pl-8 text-start',
          )}
          key={index}
          onClick={() => handleClick(index)}
        >
          <span
            className={twMerge(
              'text-[18px] font-semibold leading-[1.2] xlg:text-[24px]',
              index === currentMessage
                ? variant === 'DARK'
                  ? 'text-white'
                  : 'text-gray-900'
                : 'text-gray-400',
            )}
          >
            {message.title}
          </span>
          {index === currentMessage && (
            <span
              className={twMerge('text-base', variant === 'DARK' ? 'text-white' : 'text-gray-900')}
            >
              {message.text}
            </span>
          )}

          <div
            className={twMerge(
              'absolute bottom-0 h-full w-[3px]',
              variant === 'DARK' ? 'bg-gray-800' : 'bg-gray-100',
              side === 'RIGHT' ? 'right-0' : 'left-0',
            )}
          >
            <div
              className={twMerge(
                'h-full',
                isCompleted
                  ? 'bg-gray-100 transition-none'
                  : 'bg-brand-500 transition-all ease-linear',
              )}
              style={{ height: index === currentMessage ? `${progress}%` : 0 }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AutoSwitchMessages;
