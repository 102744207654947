import React, { FC } from 'react';

interface CapQuestLogoProps {
  size?: number;
  variant?: 'DEFAULT' | 'LIGHT';
}

const CapQuestLogo: FC<CapQuestLogoProps> = ({ size, variant = 'DEFAULT' }) => {
  return (
    <svg
      fill="none"
      height={`${size || 48}`}
      viewBox="0 0 48 48"
      width={`${size || 48}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3334 38.9261C29.8788 40.2965 27.1241 41.0405 24.3133 41.092C21.5025 41.1435 18.7224 40.5011 16.2192 39.2215C13.716 37.942 11.567 36.0648 9.96256 33.7564C8.35812 31.4479 7.34775 28.7794 7.02095 25.9872C6.69416 23.195 7.06103 20.3653 8.08906 17.7487C9.1171 15.1321 10.7746 12.8095 12.9146 10.9865C15.0547 9.16352 17.6114 7.89646 20.3581 7.29755C23.1048 6.69865 25.9569 6.78639 28.6616 7.55301L27.2927 12.3826C25.3822 11.8411 23.3677 11.7791 21.4275 12.2022C19.4873 12.6252 17.6814 13.5202 16.1698 14.8079C14.6581 16.0955 13.4874 17.7361 12.7612 19.5844C12.0351 21.4326 11.7759 23.4314 12.0068 25.4037C12.2376 27.3759 12.9513 29.2609 14.0846 30.8915C15.2179 32.522 16.7358 33.848 18.504 34.7518C20.2721 35.6556 22.2359 36.1094 24.2213 36.073C26.2067 36.0366 28.1525 35.5111 29.8863 34.5431L32.3334 38.9261Z"
        fill={variant === 'DEFAULT' ? '#083D90' : '#ffffff'}
      />
      <path
        d="M30.8151 8.32107C34.7045 10.0119 37.8265 13.0881 39.5746 16.9521C41.3228 20.816 41.5722 25.1918 40.2745 29.2294L35.4835 27.6896C36.3992 24.8406 36.2232 21.7529 34.9897 19.0264C33.7562 16.2999 31.5532 14.1293 28.8088 12.9362L30.8151 8.32107Z"
        fill="#B3B3B3"
      />
      <path
        d="M42.0704 32.4699C40.9552 35.4001 39.0557 37.9672 36.5796 39.8904L31.7656 33.6923C33.105 32.6521 34.1325 31.2635 34.7358 29.6785L42.0704 32.4699Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default CapQuestLogo;
