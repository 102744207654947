import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CapQuestLogo,
  CoinsStackedIcon,
  CrossIcon,
  DataIcon,
  FolderIcon,
  HeartIcon,
  LightningIcon,
  MenuIcon,
  PieChartIcon,
} from '../../assets/icons';
import { SolutionVariant } from '../../pages/Solutions/types';
import Button from '../Button';
import { DropDown } from '../Dropdown';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '../NavigationMenu';

type HeaderProps = {
  className?: string;
  onHoverChange?: (state: boolean) => void;
  style?: CSSProperties;
  textClassName?: string;
};

export const Header: FC<HeaderProps> = ({ className, onHoverChange, style, textClassName }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverOut = () => {
    setIsHovered(false);
    onHoverChange?.(false);
  };
  const handleHover = () => {
    setIsHovered(true);
    onHoverChange?.(true);
  };

  const navigateFunc = useNavigate();

  const navigate = (to: string, props?: NavigateOptions) => {
    navigateFunc(to, props);
    handleHoverOut();
  };

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobileMenu(false);
        setIsHovered(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsHovered]);

  return (
    <header
      className={twMerge(
        'z-100 flex w-full flex-col items-center justify-center border-b border-gray-100 bg-[#E6F0FF] transition-all duration-[400ms]',
        isHovered && 'bg-white',
        className,
      )}
      style={style}
    >
      <div className="flex h-full w-full max-w-[1280px] items-center justify-between px-4">
        <div className="flex items-center gap-10">
          <div
            className="z-20 flex cursor-pointer items-center lg:hidden"
            onClick={() => navigate('/')}
          >
            <CapQuestLogo />
            <span className={twMerge('text-base font-semibold text-gray-900', textClassName)}>
              CapQuest
            </span>
          </div>
          <NavigationMenu className="relative hidden h-full lg:block">
            <NavigationMenuList className="flex h-16 items-center gap-3 overflow-hidden">
              <NavigationMenuItem>
                <div
                  className="mr-7 flex cursor-pointer items-center justify-center"
                  onClick={() => navigate('/')}
                >
                  <CapQuestLogo />
                  <span className={twMerge('text-base font-semibold text-gray-900', textClassName)}>
                    CapQuest
                  </span>
                </div>
              </NavigationMenuItem>
              <NavigationMenuItem onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
                <NavigationMenuTrigger
                  className={twMerge('px-2 py-3 text-sm font-[550] text-gray-900', textClassName)}
                >
                  Solutions
                </NavigationMenuTrigger>
                {isHovered && (
                  <NavigationMenuContent onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
                    <div className="flex h-full w-[1280px] justify-between bg-white">
                      <div className="flex flex-wrap gap-y-4 p-10 pl-6">
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          onClick={() => {
                            navigate('/solutions', {
                              state: SolutionVariant.EQUITY_PLAN_AUTOMATION,
                            });
                          }}
                        >
                          <DataIcon className="mt-1" />

                          <div className="flex flex-col gap-4">
                            <span className={twMerge('text-base font-semibold text-gray-900')}>
                              ESOP Management
                            </span>
                            <span className="text-sm text-gray-500">
                              Eliminate paperwork and errors with automated equity mgt.
                            </span>
                          </div>
                        </div>
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          onClick={() =>
                            navigate('/solutions', {
                              state: SolutionVariant.OPTIMIZED_FUNDRAISING_INSTRUMENTS,
                            })
                          }
                        >
                          <LightningIcon className="mt-1" />
                          <div className="flex flex-col gap-4">
                            <span className={twMerge('text-base font-semibold text-gray-900')}>
                              Financial Tools
                            </span>
                            <span className="text-sm text-gray-500">
                              Streamlines cap tables, equity, and debt tracking for founders.
                            </span>
                          </div>
                        </div>
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          onClick={() =>
                            navigate('/solutions', {
                              state: SolutionVariant.SIMPLIFIED_CAP_TABLE,
                            })
                          }
                        >
                          <CoinsStackedIcon className="mt-1" />
                          <div className="flex flex-col gap-4">
                            <span className={twMerge('text-base font-semibold text-gray-900')}>
                              Cap Table Management
                            </span>
                            <span className="text-sm text-gray-500">
                              Manage cap tables effortlessly with real-time updates and reports.
                            </span>
                          </div>
                        </div>
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          onClick={() =>
                            navigate('/solutions', {
                              state: SolutionVariant.ENHANCED_INVESTOR_RELATIONS,
                            })
                          }
                        >
                          <HeartIcon className="mt-1" />
                          <div className="flex flex-col gap-4">
                            <span className={twMerge('text-base font-semibold text-gray-900')}>
                              Investor Relations
                            </span>
                            <span className="text-sm text-gray-500">
                              Build trust with real-time equity insights, empowering investors.
                            </span>
                          </div>
                        </div>
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          onClick={() =>
                            navigate('/solutions', {
                              state: SolutionVariant.EFFORTLESS_SCENARIO_MODELING,
                            })
                          }
                        >
                          <PieChartIcon className="mt-1 size-6" />
                          <div className="flex flex-col gap-4">
                            <span className={twMerge('text-base font-semibold text-gray-900')}>
                              Scenario Modeling
                            </span>
                            <span className="text-sm text-gray-500">
                              Visualize impacts of financing and exits with scenario modeling.
                            </span>
                          </div>
                        </div>
                        <div
                          className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                          // onClick={() =>
                          //   navigate('/solutions', {
                          //     state: SolutionVariant.EFFORTLESS_SCENARIO_MODELING,
                          //   })
                          // }
                        >
                          <FolderIcon className="mt-1 size-6" />
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-4">
                              <span className={twMerge('text-base font-semibold text-gray-900')}>
                                Data Room
                              </span>
                              <span
                                className="rounded-[32px] border-[1px] border-[#344054] px-2 text-[8px] font-semibold leading-[2.2] text-white"
                                style={{
                                  background: 'linear-gradient(180deg, #344054 0%, #475467 100%)',
                                }}
                              >
                                Coming Soon
                              </span>
                            </div>
                            <span className="text-sm text-gray-500">
                              Secure document sharing for business transactions and needs.
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="w-[304px] shrink-0 bg-sunbeam-100"></div> */}
                    </div>
                  </NavigationMenuContent>
                )}
              </NavigationMenuItem>
              <NavigationMenuItem>
                {/* <Link
                  className={twMerge(
                    'h-[48px] px-2 py-3 text-sm font-[550] text-gray-900',
                    textClassName,
                  )}
                  to={'/about'}
                >
                  <NavigationMenuLink>About Us</NavigationMenuLink>
                </Link> */}
                <NavigationMenuItem>
                  <Button
                    className={twMerge(
                      'bg-transparent py-[14px] font-inter text-sm font-[500] text-gray-900',
                      textClassName,
                    )}
                    onClick={() => navigate('/about')}
                  >
                    About us
                  </Button>
                </NavigationMenuItem>
              </NavigationMenuItem>
              {/* <NavigationMenuItem>
                <NavigationMenuTrigger
                  className={twMerge(
                    'px-2 py-[10px] text-sm font-[550] text-gray-900',
                    textClassName,
                  )}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHoverOut}
                >
                  Resources
                </NavigationMenuTrigger>
                <NavigationMenuContent onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
                  Content
                </NavigationMenuContent>
              </NavigationMenuItem> */}
              {/* <NavigationMenuItem>
                <Link
                  className={twMerge(
                    'px-2 py-[10px] text-sm font-[550] text-gray-900',
                    textClassName,
                  )}
                  to={'/pricing'}
                >
                  <NavigationMenuLink>Pricing</NavigationMenuLink>
                </Link>
              </NavigationMenuItem> */}
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        <div className="flex items-center gap-3">
          <div className="hidden items-center gap-1 xlg:flex">
            <Button
              className={twMerge('hover:text-gray-900', textClassName)}
              onClick={() => navigate('/contact-sales')}
              styleType="DEFAULT_ROUNDED"
            >
              Contact Sales
            </Button>
            {/* <a
              className={twMerge(
                'flex w-full cursor-pointer items-center justify-center text-nowrap rounded bg-transparent px-4 py-[14px] font-inter text-sm font-[500] text-gray-900 transition-all duration-300 hover:bg-[white] hover:text-gray-900',
                textClassName,
              )}
              href={process.env.REACT_APP_CAP_QUEST_LOGIN_URL}
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Log in
            </a> */}
          </div>
          {/* <a
            className={twMerge(
              'hidden w-full cursor-pointer items-center justify-center text-nowrap rounded bg-brand-700 px-8 py-[14px] font-inter text-sm font-[500] text-white transition-all duration-300 hover:bg-[#146EF5] xlg:flex',
            )}
            href={process.env.REACT_APP_CAP_QUEST_REGISTER_URL}
            rel="noreferrer noopener nofollow"
            target="_blank"
          >
            Get Started
          </a> */}
          <div
            className="cursor-pointer pl-3 lg:hidden"
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
              setIsHovered(!isHovered);
            }}
          >
            {showMobileMenu ? <CrossIcon /> : <MenuIcon />}
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div
          className="mt-[10px] flex w-full flex-col overflow-y-scroll border-t-[1px] border-[#1018280F] bg-white px-4 xlg:h-fit"
          style={{ height: 'calc(100vh - 68px)' }}
        >
          <div className="flex-col">
            <DropDown className="border-b-[1px] border-[#1018280F]" title="Solutions">
              <div className="flex flex-col items-center justify-center gap-[72px] pb-6 pt-2 xlg:flex-row">
                <div className="flex flex-col">
                  <div className="flex flex-wrap items-center justify-center gap-y-4 p-10 pl-6 xlg:items-start xlg:justify-start">
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate('/solutions', {
                          state: SolutionVariant.EQUITY_PLAN_AUTOMATION,
                        });
                      }}
                    >
                      <DataIcon className="mt-1" />

                      <div className="flex flex-col gap-4">
                        <span className="text-base font-semibold text-gray-900">
                          ESOP Management
                        </span>
                        <span className="text-sm text-gray-500">
                          Eliminate paperwork and errors with automated equity mgt.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate('/solutions', {
                          state: SolutionVariant.OPTIMIZED_FUNDRAISING_INSTRUMENTS,
                        });
                      }}
                    >
                      <LightningIcon className="mt-1" />
                      <div className="flex flex-col gap-4">
                        <span className="text-base font-semibold text-gray-900">
                          Financial Tools
                        </span>
                        <span className="text-sm text-gray-500">
                          Streamlines cap tables, equity, and debt tracking for founders.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate('/solutions', {
                          state: SolutionVariant.SIMPLIFIED_CAP_TABLE,
                        });
                      }}
                    >
                      <CoinsStackedIcon className="mt-1" />
                      <div className="flex flex-col gap-4">
                        <span className="text-base font-semibold text-gray-900">
                          Cap Table Management
                        </span>
                        <span className="text-sm text-gray-500">
                          Manage cap tables effortlessly with real-time updates and reports.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate('/solutions', {
                          state: SolutionVariant.ENHANCED_INVESTOR_RELATIONS,
                        });
                      }}
                    >
                      <HeartIcon className="mt-1" />
                      <div className="flex flex-col gap-4">
                        <span className="text-base font-semibold text-gray-900">
                          Investor Relations
                        </span>
                        <span className="text-sm text-gray-500">
                          Build trust with real-time equity insights, empowering investors.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate('/solutions', {
                          state: SolutionVariant.EFFORTLESS_SCENARIO_MODELING,
                        });
                      }}
                    >
                      <PieChartIcon className="mt-1 size-6" />
                      <div className="flex flex-col gap-4">
                        <span className="text-base font-semibold text-gray-900">
                          Scenario Modeling
                        </span>
                        <span className="text-sm text-gray-500">
                          Visualize impacts of financing and exits with scenario modeling.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-[304px] cursor-pointer items-start justify-start gap-4 p-4 text-start"
                      onClick={() => navigate('/solutions')}
                    >
                      <FolderIcon className="mt-1 size-6" />
                      <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-4">
                          <span className="text-base font-semibold text-gray-900">Data Room</span>
                          <span
                            className="rounded-[32px] border-[1px] border-[#344054] px-2 text-[8px] font-semibold leading-[2.2] text-white"
                            style={{
                              background: 'linear-gradient(180deg, #344054 0%, #475467 100%)',
                            }}
                          >
                            Coming Soon
                          </span>
                        </div>
                        <span className="text-sm text-gray-500">
                          Secure document sharing for business transactions and needs.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="h-[286px] w-[361px] shrink-0 rounded-lg bg-sunbeam-100"></div> */}
              </div>
            </DropDown>
            {/* <Button
              className="flex w-full items-start justify-start px-2 py-4 text-sm font-[550] text-gray-900"
              onClick={() => navigate('/pricing')}
              styleType="NONE"
            >
              Pricing
            </Button> */}
          </div>
          <div className="mt-auto flex flex-col gap-4 border-t-[1px] border-[#1018280F] px-4 py-6 xlg:hidden">
            <div className="flex items-center gap-3">
              <Button
                className="border-[1px] border-gray-300"
                onClick={() => navigate('/contact-sales')}
                styleType="DEFAULT_ROUNDED"
              >
                Book a Demo
              </Button>
              {/* <a
                className="flex w-full cursor-pointer items-center justify-center text-nowrap rounded border-[1px] border-gray-300 bg-transparent px-4 py-[14px] font-inter text-sm font-[500] text-gray-900 transition-all duration-300 hover:bg-[white]"
                href={process.env.REACT_APP_CAP_QUEST_LOGIN_URL}
                rel="noreferrer noopener nofollow"
                target="_blank"
              >
                Log in
              </a> */}
            </div>
            {/* <a
              className="flex w-full cursor-pointer items-center justify-center text-nowrap rounded bg-brand-700 px-8 py-[14px] font-inter text-sm font-[500] text-white transition-all duration-300 hover:bg-[#146EF5]"
              href={process.env.REACT_APP_CAP_QUEST_REGISTER_URL}
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Get Started
            </a> */}
          </div>
        </div>
      )}
    </header>
  );
};
