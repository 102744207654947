import React from 'react';

const SuccessIcon = () => {
  return (
    <svg fill="none" height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#12B76A" height="72" rx="36" width="72" />
      <path
        d="M54 22.9087L29.25 47.6587L18 36.4087"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
    </svg>
  );
};

export default SuccessIcon;
