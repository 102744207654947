import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ColumnsIconProps = IconProps;

const ColumnsIcon: FC<ColumnsIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56609 2.69775V14.5328M10.5111 2.69775V14.5328M5.77709 2.69775H11.3001C12.4048 2.69775 12.9571 2.69775 13.3791 2.91274C13.7502 3.10185 14.052 3.40361 14.2411 3.77476C14.4561 4.1967 14.4561 4.74905 14.4561 5.85375V11.3768C14.4561 12.4815 14.4561 13.0338 14.2411 13.4558C14.052 13.8269 13.7502 14.1287 13.3791 14.3178C12.9571 14.5328 12.4048 14.5328 11.3001 14.5328H5.77709C4.67239 14.5328 4.12004 14.5328 3.6981 14.3178C3.32695 14.1287 3.02519 13.8269 2.83608 13.4558C2.62109 13.0338 2.62109 12.4815 2.62109 11.3768V5.85375C2.62109 4.74905 2.62109 4.1967 2.83608 3.77476C3.02519 3.40361 3.32695 3.10185 3.6981 2.91274C4.12004 2.69775 4.67239 2.69775 5.77709 2.69775Z"
        stroke={iconColor || '#344054'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.932455"
      />
    </svg>
  );
};

export default ColumnsIcon;
