import { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { twMerge } from 'tailwind-merge';

import { CapQuestPricingLogo, CheckIcon } from '../../assets/icons';
import Button from '../../components/Button';
import CompanySlider from '../../components/CompanySlider/CompanySlider';
import FinalSection from '../../components/FinalSection/FinalSection';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { EnterpriseCard, PricingCard } from './PricingCard';
import { QuestionDropDown } from './QuestionDropDown';
import {
  listOfSubscriptionBenefits,
  PricingPlan,
  SubscriptionFrequency,
  subscriptionFrequencyTitle,
  SubscriptionType,
  subscriptionTypeTitleMap,
} from './types';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const pricingTypeTitleMap = Object.entries(subscriptionFrequencyTitle);

export const Pricing: FC = () => {
  const redirectToAuth = () =>
    window.location.replace(process.env.REACT_APP_CAP_QUEST_REGISTER_URL || '');

  const [selectedBilling, setBillingType] = useState(SubscriptionFrequency.ANNUALY);
  const [selectedPlanType, setPlanType] = useState(SubscriptionType.START_UP);

  const { data: plans = [] } = useQuery<PricingPlan[]>(['basic-pricing-plans'], {
    queryFn: async () =>
      (
        await fetch(`${backendUrl}/plans/basic`, {
          headers: { 'Content-Type': 'application/json' },
        })
      ).json(),
  });

  const plansByDuration = useMemo(
    () =>
      plans?.reduce<Record<SubscriptionFrequency, PricingPlan[]>>(
        (acc, plan) => {
          const planDuration = plan.duration;
          if (!acc[planDuration]) {
            acc[planDuration] = [];
          }
          acc[planDuration].push(plan);
          return acc;
        },
        {} as Record<SubscriptionFrequency, PricingPlan[]>,
      ),
    [plans],
  );

  const allSubscriptionAdvancedBenefits = Object.entries(listOfSubscriptionBenefits).reduce<
    string[]
  >(
    (acc, [type, benefits]) => (type !== SubscriptionType.START_UP ? [...acc, ...benefits] : acc),
    [],
  );

  return (
    <div className="relative text-center font-inter text-sm font-normal text-gray-700">
      <main className="flex w-full flex-col overflow-x-hidden">
        <section className="flex w-full flex-col bg-[#020811] px-4 pb-20">
          <Header className="border-[#E6F0FF1A] bg-transparent" textClassName="text-white" />
          <div
            className="relative flex h-[500px] w-full flex-col items-center justify-end"
            style={{
              background: 'linear-gradient(180deg, rgba(2, 8, 17, 0.1) 2.01%, #020811 100%)',
            }}
          >
            <div
              className="absolute left-[35%] top-[30%] h-[200px] w-[300px] opacity-40 blur-[220px]"
              style={{ background: '#D92D20' }}
            />
            <div
              className="absolute right-[35%] top-[30%] h-[200px] w-[200px] opacity-35 blur-[220px]"
              style={{ background: '#039855' }}
            />
            <div
              className="absolute bottom-[20%] left-[45%] h-[200px] w-[200px] blur-[220px]"
              style={{ background: '#2565C8' }}
            />
            <CapQuestPricingLogo className="absolute" />
            <div className="z-1 flex max-w-[500px] flex-col gap-6 pb-20 text-center">
              <span className="w-full text-xxl font-semibold text-white">
                Pricing that scales with you
              </span>
              <span className="mx-12 text-base text-[#C6C6C6]">
                Efficiently handle stock option management with our cost-saving annual plans
              </span>
            </div>
          </div>
          <div className="flex h-full w-full flex-col items-center justify-center gap-16 bg-[#020811]">
            <section className="flex h-[50px] w-fit justify-stretch overflow-hidden rounded-3xl border-2 border-white p-1">
              {pricingTypeTitleMap.map(([key, title]) => (
                <Button
                  className={twMerge(
                    'h-full w-full rounded-[32px] bg-transparent px-4 text-white',
                    selectedBilling === key && 'bg-brand-700',
                  )}
                  key={key}
                  onClick={() => setBillingType(key)}
                >
                  {title}
                </Button>
              ))}
            </section>
            <div className="flex h-fit w-full max-w-[1000px] flex-wrap gap-4 fill-forest-500">
              {plansByDuration[selectedBilling]
                ?.sort((a, b) => a.stakeholdersCount - b.stakeholdersCount)
                ?.map(({ id, ...plan }) => <PricingCard {...plan} key={id} />)}

              <EnterpriseCard />
            </div>
            <section className="flex w-full flex-col gap-12 border-b border-[#E6F0FF1A] py-14 text-center">
              <span className="text-base text-gray-600">
                Trusted by leading companies and partners from around the world
              </span>
              <CompanySlider />
            </section>
            <section className="relative flex w-full max-w-[1000px] flex-col items-center gap-4 text-sm font-[450] text-[#C6C6C6] max-lg:hidden">
              <div className="absolute right-0 top-0 size-[600px] translate-x-full bg-[#2565C8] opacity-20 blur-[476px]" />
              <div className="flex w-full justify-between">
                <div className="w-[18%] text-l font-bold text-white" />
                <div className="flex flex-col gap-4 text-l font-bold text-white">
                  Start Up
                  <Button
                    className="mt-2 h-11 w-full bg-brand-700 px-6 text-sm font-[550] text-white"
                    onClick={redirectToAuth}
                    style={{
                      boxShadow: '0px 1px 2px 0px #B4D1FE3D inset',
                    }}
                  >
                    Get Started
                  </Button>
                </div>
                <div className="flex flex-col gap-4 text-l font-bold text-white">
                  Growth{' '}
                  <Button
                    className="mt-2 h-11 w-full bg-brand-700 px-6 text-sm font-[550] text-white"
                    onClick={redirectToAuth}
                    style={{
                      boxShadow: '0px 1px 2px 0px #B4D1FE3D inset',
                    }}
                  >
                    Get Started
                  </Button>
                </div>
                <div className="flex flex-col gap-4 text-l font-bold text-white">
                  Enterise{' '}
                  <Button
                    className="mt-2 h-11 w-full bg-brand-700 px-6 text-sm font-[550] text-white"
                    onClick={redirectToAuth}
                    style={{
                      boxShadow: '0px 1px 2px 0px #B4D1FE3D inset',
                    }}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
              <div className="flex w-full flex-col gap-4">
                <span className="w-full text-l font-bold text-white">Usage</span>

                <div className="flex w-full justify-between">
                  <span className="w-[230px]">Stakeholders</span>
                  {plansByDuration[selectedBilling]?.map(({ stakeholdersCount, id }) => (
                    <span className="w-[100px]" key={id}>
                      {stakeholdersCount}
                    </span>
                  ))}
                  <span className="w-[100px]">50+</span>
                </div>

                {listOfSubscriptionBenefits[SubscriptionType.START_UP].map((benefit) => (
                  <div className="flex w-full justify-between" key={benefit}>
                    <span className="w-[230px]">{benefit}</span>
                    <span className="w-[100px]">
                      <CheckIcon iconColor="#D0D5DD" />
                    </span>
                    <span className="w-[100px]">
                      <CheckIcon iconColor="#D0D5DD" />
                    </span>
                    <span className="w-[100px]">
                      <CheckIcon iconColor="#D0D5DD" />
                    </span>
                  </div>
                ))}
              </div>

              <div className="mt-10 flex w-full flex-col gap-4">
                <span className="w-full text-l font-bold text-white">Advantage</span>

                {allSubscriptionAdvancedBenefits.map((benefit) => (
                  <div className="flex w-full justify-between" key={benefit}>
                    <span className="w-[230px]">{benefit}</span>
                    <span className="w-[100px]">
                      {listOfSubscriptionBenefits.startup.includes(benefit) && (
                        <CheckIcon iconColor="#D0D5DD" />
                      )}
                    </span>
                    <span className="w-[100px]">
                      {listOfSubscriptionBenefits.growth.includes(benefit) && (
                        <CheckIcon iconColor="#D0D5DD" />
                      )}
                    </span>
                    <span className="w-[100px]">
                      <CheckIcon iconColor="#D0D5DD" />
                    </span>
                  </div>
                ))}
              </div>
            </section>

            <section className="relative flex w-full max-w-[1000px] flex-col items-center gap-8 text-sm font-[450] text-[#C6C6C6] lg:hidden">
              <div className="absolute right-0 top-0 size-[600px] translate-x-full bg-[#2565C8] blur-[476px]" />

              <div className="flex w-full border-b border-[#E6F0FF1A]">
                {subscriptionTypeTitleMap.map(([planType, title]) => (
                  <Button
                    className={twMerge(
                      'rounded-none p-4 text-sm font-[450] text-gray-400 transition-none',
                      selectedPlanType === planType &&
                        'border-b border-white font-[550] text-white',
                    )}
                    key={planType}
                    onClick={() => setPlanType(planType)}
                  >
                    {title}
                  </Button>
                ))}
              </div>
              <div className="flex w-full flex-col gap-4">
                <div className="flex w-full justify-between">
                  <span>Stakeholders</span>

                  <span className="w-[70px]">
                    {plansByDuration[selectedBilling]?.find(({ type }) => type === selectedPlanType)
                      ?.stakeholdersCount || '50+'}
                  </span>
                </div>

                {listOfSubscriptionBenefits[selectedPlanType].map((benefit) => (
                  <div className="flex w-full justify-between" key={benefit}>
                    <span>{benefit}</span>
                    <span className="w-[70px]">
                      <CheckIcon iconColor="#D0D5DD" />
                    </span>
                  </div>
                ))}
              </div>
              <div className="mt-10 flex w-full flex-col gap-4">
                <span className="w-full text-l font-bold text-white">Advantage</span>
                {allSubscriptionAdvancedBenefits.map((benefit) => (
                  <div className="flex w-full justify-between" key={benefit}>
                    <span>{benefit}</span>
                    <span className="w-[70px]">
                      {(listOfSubscriptionBenefits[selectedPlanType].includes(benefit) ||
                        selectedPlanType === SubscriptionType.ENTERPRISE) && (
                        <CheckIcon iconColor="#D0D5DD" />
                      )}
                    </span>
                  </div>
                ))}
              </div>
              <Button
                className="mt-2 h-11 w-full bg-brand-700 text-sm font-[550] text-white"
                onClick={redirectToAuth}
                style={{
                  boxShadow: '0px 1px 2px 0px #B4D1FE3D inset',
                }}
              >
                Get Started
              </Button>
            </section>
          </div>
        </section>
        <section
          className="flex h-fit w-full justify-center border border-b border-[#10182814] bg-white px-4 py-20"
          style={{
            background:
              'radial-gradient(37.94% 60.34% at 50.36% 38.25%, rgba(152, 162, 179, 0) 0%, rgba(249, 250, 251, 0.28) 100%)',
          }}
        >
          <div className="flex max-w-[1000px] flex-col gap-20 text-center">
            <div className="flex flex-col gap-4">
              <span className="text-3xl font-semibold text-[#101828]">
                Frequently asked questions
              </span>

              <div>
                <span className="text-base text-[#475467]">
                  Have other questions? Get in touch with our team via
                </span>{' '}
                <a
                  className="cursor-pointer text-base font-semibold text-[#475467] underline"
                  href="mailto:info@capquest.io"
                >
                  info@capquest.io
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <QuestionDropDown
                answer="Yes, your payment is fully secure. We use advanced encryption technology and
                  adhere to strict security protocols to protect your information and ensure a safe
                  transaction experience."
                question="Is my payment secure?"
              />

              <QuestionDropDown
                answer="We accept a variety of payment methods, including major credit cards (Visa, MasterCard, American Express), PayPal, and bank transfers. This variety allows you to choose the method that best suits your needs."
                question="What are the payment method you accept?"
              />
              <QuestionDropDown
                answer="We process your data in accordance with industry standards and strict privacy laws. Your data is encrypted and securely stored, and we only use it to provide you with seamless service and support. For more details, please see our Privacy Policy."
                question="How do you process my data?"
              />
              <QuestionDropDown
                answer="Our support team is available via email, chat, and phone to assist you with any questions or concerns. Whether you need help with billing, account setup, or technical issues, we're here to provide prompt and reliable support."
                question="How does support works?"
              />
              <QuestionDropDown
                answer="Switching from the Startup to the Growth Plan is simple. You can upgrade your plan directly in your account settings, and the changes will take effect immediately. If you need assistance, our support team is here to help."
                question="How do I switch from Startup to Growth Plan?"
              />
              <QuestionDropDown
                answer="Yes, you can cancel your subscription at any time through your account settings. Upon cancellation, you’ll continue to have access to your plan features until the end of your current billing cycle."
                question="Can I cancel my subscription anytime?"
              />
              <QuestionDropDown
                answer="We offer a satisfaction guarantee and honor refunds in line with our policy. If you’re not completely satisfied within the initial period, you may be eligible for a refund. For complete details, please refer to our Refund Policy."
                question="What is your refund policy?"
              />
            </div>
          </div>
        </section>
        <FinalSection />
        <Footer />
      </main>
    </div>
  );
};
