import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CrossIconProps = IconProps;

const CrossIcon: FC<CrossIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={iconColor || '#344054'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CrossIcon;
