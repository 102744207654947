import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { SolutionsConstructor } from './SolutionsConstructor';
import { solutions, SolutionVariant } from './types';

export const Solutions: FC = () => {
  const location = useLocation();
  const initialTab =
    (location.state as SolutionVariant | undefined) || SolutionVariant.EQUITY_PLAN_AUTOMATION;

  return <SolutionsConstructor {...solutions[initialTab]} />;
};
