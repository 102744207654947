import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type PieChartIconProps = IconProps;

const PieChartIcon: FC<PieChartIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7104 15.8901C21.0742 17.3946 20.0792 18.7203 18.8123 19.7514C17.5454 20.7825 16.0452 21.4875 14.4428 21.8048C12.8405 22.1222 11.1848 22.0422 9.62055 21.5719C8.05627 21.1015 6.63103 20.2551 5.46942 19.1067C4.30782 17.9583 3.44522 16.5428 2.95704 14.984C2.46886 13.4252 2.36996 11.7706 2.669 10.1647C2.96804 8.55885 3.6559 7.0507 4.67245 5.7721C5.689 4.4935 7.00329 3.48339 8.5004 2.83007M21.7392 8.17323C22.1395 9.1397 22.3851 10.1614 22.4684 11.2009C22.489 11.4577 22.4993 11.5861 22.4483 11.7018C22.4057 11.7984 22.3213 11.8898 22.2284 11.94C22.1172 12.0001 21.9783 12.0001 21.7004 12.0001H13.3004C13.0204 12.0001 12.8804 12.0001 12.7734 11.9456C12.6793 11.8976 12.6028 11.8211 12.5549 11.7271C12.5004 11.6201 12.5004 11.4801 12.5004 11.2001V2.80007C12.5004 2.5222 12.5004 2.38327 12.5605 2.27205C12.6107 2.17915 12.7021 2.09476 12.7987 2.05216C12.9144 2.00117 13.0428 2.01146 13.2996 2.03205C14.339 2.11539 15.3608 2.36095 16.3272 2.76127C17.5405 3.26382 18.6429 4.00042 19.5715 4.929C20.5001 5.85759 21.2367 6.95998 21.7392 8.17323Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PieChartIcon;
