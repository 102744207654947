import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { About, Home, Solutions } from './pages';
import { ContactSales } from './pages/ContactSales';
import { Terms } from './pages/Terms';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/solutions',
    element: <Solutions />,
  },
  // {
  //   path: '/pricing',
  //   element: <Pricing />,
  // },
  { path: '/contact-sales', element: <ContactSales /> },
  { path: '/terms-of-service', element: <Terms /> },
  { path: '/about', element: <About /> },
  {
    path: '*',
    element: <Home />,
  },
]);

export default router;
