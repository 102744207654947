import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CalculatorIcon,
  ColumnsIcon,
  ContentIcon,
  CursorIcon,
  DataIcon,
  PieChartIcon,
} from '../../assets/icons';
import acmeIncImg from '../../assets/images/hero/acme-inc.png';
import addButtonImg from '../../assets/images/hero/add-button.png';
import eventsImg from '../../assets/images/hero/events.png';
import exerciseImg from '../../assets/images/hero/exercise-request.png';
import graphImg from '../../assets/images/hero/graph.png';
import hoveredGraphImg from '../../assets/images/hero/hovered-graph.png';
import overAllStatusImg from '../../assets/images/hero/overall-status.png';
import progressBarImg from '../../assets/images/hero/progress-bar.png';

export const HeroAnimatedImage: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentGraphImg, setCurrentGraphImg] = useState(graphImg);
  const [isFirstImage, setIsFirstImage] = useState(true);

  useEffect(() => {
    const intervals = [2000, 3300, 4600];
    let currentIndex = 0;

    const updateIndex = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
      currentIndex = (currentIndex + 1) % intervals.length;
      setTimeout(updateIndex, intervals[currentIndex]);
    };
    const timeout = setTimeout(updateIndex, intervals[currentIndex]);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGraphImg((prev) => (isFirstImage ? hoveredGraphImg : graphImg));
      setIsFirstImage((prev) => !prev);
    }, 3000);

    return () => clearInterval(interval);
  }, [isFirstImage]);

  return (
    <div className="relative h-[296px] w-[361px] xlg:h-[549px] xlg:w-[670px]">
      <div
        className="absolute left-[12px] top-[77px] z-1 rounded-[32px] border-[1px] border-white px-[6px] py-[3px] text-[6px] font-medium leading-[1.3] text-gray-50 xlg:left-[40px] xlg:top-[143px] xlg:px-3 xlg:text-label-md"
        style={{
          boxShadow: '0px 4px 12px 0px #1018283D',
          background: 'linear-gradient(180deg, #039855 0%, #12B76A 100%)',
          animation: 'moveGreenCursor 10s infinite',
        }}
      >
        Founder
        <CursorIcon
          className="absolute -right-[6px] -top-[6px] size-[11px] xlg:-right-3 xlg:-top-3 xlg:size-[21px]"
          iconColor="#12B76A"
        />
      </div>
      <div
        className="absolute bottom-[50px] left-0 z-1 rounded-[32px] border-[1px] border-white px-[6px] py-[3px] text-[6px] font-medium leading-[1.3] text-gray-50 xlg:bottom-[94px] xlg:left-2 xlg:px-3 xlg:text-label-md"
        style={{
          boxShadow: '0px 4px 12px 0px #1018283D',
          background: 'linear-gradient(180deg, #DC6803 0%, #F79009 100%)',
          animation: 'moveOrangeCursor 10s infinite',
        }}
      >
        Employee
        <CursorIcon className="absolute -right-[6px] -top-[6px] size-[11px] xlg:-right-3 xlg:-top-3 xlg:size-[21px]" />
      </div>
      <div
        className="absolute right-1 top-0 z-1 rounded-[32px] border-[1px] border-white px-[6px] py-[3px] text-[6px] font-medium leading-[1.3] text-gray-50 xlg:right-8 xlg:px-3 xlg:text-label-md"
        style={{
          boxShadow: '0px 4px 12px 0px #1018283D',
          background: 'linear-gradient(180deg, #D92D20 0%, #F04438 100%)',
          animation: 'moveRedCursor 10s infinite',
        }}
      >
        Admin
        <CursorIcon
          className="absolute -bottom-[6px] -right-[6px] size-[11px] rotate-90 xlg:-bottom-3 xlg:-right-3 xlg:size-[21px]"
          iconColor="#F04438"
        />
      </div>
      <div
        className="absolute -right-2 bottom-[12px] z-1 rounded-[32px] border-[1px] border-white px-[6px] py-[3px] text-[6px] font-medium leading-[1.3] text-gray-50 xlg:-right-4 xlg:bottom-[45px] xlg:px-3 xlg:text-label-md"
        style={{
          boxShadow: '0px 4px 12px 0px #1018283D',
          background: 'linear-gradient(180deg, #2565C8 0%, #3E7BDA 100%)',
          animation: 'moveBlueCursor 6s infinite',
        }}
      >
        Investor
        <CursorIcon
          className="absolute -left-[6px] -top-[6px] size-[11px] -rotate-90 xlg:-left-3 xlg:-top-3 xlg:size-[21px]"
          iconColor="#3E7BDA"
        />
      </div>
      <div
        className="absolute right-[59px] top-[20px] h-[108px] w-[231px] bg-cover bg-no-repeat xlg:right-[109px] xlg:top-[37px] xlg:h-[202px] xlg:w-[422px]"
        style={{
          backgroundImage: `url(${overAllStatusImg})`,
        }}
      />
      <div
        className="absolute right-0 top-[43px] h-[96px] w-[62px] bg-cover bg-no-repeat xlg:top-[80px] xlg:h-[179px] xlg:w-[114px]"
        style={{
          backgroundImage: `url(${acmeIncImg})`,
        }}
      />

      <div className="absolute right-0 top-[20px] flex gap-[3px] xlg:top-[37px]">
        <div
          className={twMerge(
            'flex size-4 items-center justify-center rounded-[5px] transition-all duration-300 ease-in-out xlg:size-8',
            activeIndex === 2 ? 'bg-white' : 'bg-transparent',
          )}
        >
          <DataIcon
            className={'size-2 xlg:size-4'}
            iconColor={activeIndex === 2 ? '#2565C8' : '#98A2B3'}
          />
        </div>
        <div
          className={twMerge(
            'flex size-4 items-center justify-center rounded-[5px] transition-all duration-300 ease-in-out xlg:size-8',
            activeIndex === 1 ? 'bg-white' : 'bg-transparent',
          )}
        >
          <PieChartIcon
            className="size-2 xlg:size-4"
            iconColor={activeIndex === 1 ? '#2565C8' : '#98A2B3'}
          />
        </div>
        <div
          className={twMerge(
            'flex size-4 items-center justify-center rounded-[5px] transition-all duration-300 ease-in-out xlg:size-8',
            activeIndex === 0 ? 'bg-white' : 'bg-transparent',
          )}
        >
          <ContentIcon
            className="size-2 xlg:size-4"
            iconColor={activeIndex === 0 ? '#2565C8' : '#98A2B3'}
          />
        </div>
      </div>

      <div
        className="absolute left-0 top-[133px] h-[75px] w-[189px] bg-cover bg-no-repeat xlg:top-[248px] xlg:h-[140px] xlg:w-[346px]"
        style={{
          backgroundImage: `url(${exerciseImg})`,
        }}
      />
      <div
        className="absolute right-[33px] top-[133px] h-[20px] w-[132px] bg-cover bg-no-repeat xlg:right-[60px] xlg:top-[242px] xlg:h-[38px] xlg:w-[264px]"
        style={{
          backgroundImage: `url(${progressBarImg})`,
        }}
      />

      <div className="absolute right-0 top-[138px] flex gap-2 xlg:top-[256px]">
        <div className="flex size-4 items-center justify-center rounded-[5px] bg-white xlg:size-8">
          <ColumnsIcon className={'size-2 xlg:size-4'} />
        </div>
        <div className="flex size-4 items-center justify-center rounded-[5px] bg-white xlg:size-8">
          <CalculatorIcon className={'size-2 xlg:size-4'} />
        </div>
      </div>

      <div
        className="absolute right-[130px] top-[159px] h-[49px] w-[45px] bg-cover bg-no-repeat xlg:right-[244px] xlg:top-[295px] xlg:h-[92px] xlg:w-[82px]"
        style={{
          backgroundImage: `url(${addButtonImg})`,
        }}
      />
      <div
        className="duration-3000 absolute bottom-0 right-0 h-[134px] w-[133px] bg-cover bg-no-repeat transition-all ease-in-out xlg:h-[250px] xlg:w-[243px]"
        style={{
          backgroundImage: `url(${currentGraphImg})`,
        }}
      />
      <div
        className="absolute bottom-0 left-[70px] h-[82px] w-[151px] bg-cover bg-no-repeat xlg:left-[141px] xlg:h-[152px] xlg:w-[276px]"
        style={{
          backgroundImage: `url(${eventsImg})`,
        }}
      />
    </div>
  );
};
