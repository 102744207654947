import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type StarsIconProps = IconProps;

const StarsIcon: FC<StarsIconProps> = ({ className, iconColor = '#101828', ...props }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4165 10.8332L6.07022 12.1406C6.29146 12.5831 6.40208 12.8043 6.54986 12.996C6.681 13.1662 6.83351 13.3187 7.00363 13.4498C7.19535 13.5976 7.41659 13.7082 7.85907 13.9295L9.1665 14.5832L7.85907 15.2369C7.41659 15.4581 7.19535 15.5687 7.00363 15.7165C6.83351 15.8477 6.681 16.0002 6.54986 16.1703C6.40208 16.362 6.29146 16.5833 6.07022 17.0257L5.4165 18.3332L4.76279 17.0257C4.54155 16.5833 4.43093 16.362 4.28314 16.1703C4.15201 16.0002 3.9995 15.8477 3.82938 15.7165C3.63766 15.5687 3.41642 15.4581 2.97393 15.2369L1.6665 14.5832L2.97393 13.9295C3.41642 13.7082 3.63766 13.5976 3.82938 13.4498C3.9995 13.3187 4.15201 13.1662 4.28314 12.996C4.43093 12.8043 4.54155 12.5831 4.76279 12.1406L5.4165 10.8332Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        d="M12.4998 1.6665L13.482 4.22019C13.717 4.83121 13.8345 5.13672 14.0173 5.39371C14.1792 5.62146 14.3782 5.82046 14.606 5.98241C14.863 6.16514 15.1685 6.28264 15.7795 6.51765L18.3332 7.49984L15.7795 8.48202C15.1685 8.71703 14.863 8.83454 14.606 9.01727C14.3782 9.17922 14.1792 9.37821 14.0173 9.60597C13.8345 9.86295 13.717 10.1685 13.482 10.7795L12.4998 13.3332L11.5177 10.7795C11.2826 10.1685 11.1651 9.86295 10.9824 9.60597C10.8205 9.37821 10.6215 9.17922 10.3937 9.01727C10.1367 8.83454 9.83121 8.71703 9.22019 8.48202L6.6665 7.49984L9.22019 6.51765C9.83121 6.28264 10.1367 6.16514 10.3937 5.98241C10.6215 5.82046 10.8205 5.62146 10.9824 5.39371C11.1651 5.13672 11.2826 4.83121 11.5177 4.22019L12.4998 1.6665Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default StarsIcon;
