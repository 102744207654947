import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ArrowRightIcon,
  CheckCircleIcon,
  DataIcon,
  PieChartIcon,
  UsersIcon,
} from '../../assets/icons';
import Button from '../Button';
import { HeroAnimatedImage } from './HeroAnimatedImage';

export const Hero: FC = () => {
  const navigate = useNavigate();
  return (
    <section className="px-4 pb-[56px] pt-[56px] xlg:pt-[72px] lg:pb-[125px] lg:pt-[88px]">
      <div className="ml-auto mr-auto flex max-w-[1280px] flex-col items-center justify-center gap-6 lg:flex-row lg:gap-0">
        <div className="flex max-w-[621px] flex-grow basis-[48%] flex-col items-center gap-6 pb-6 lg:items-start lg:pt-6 lg:text-start">
          <h2 className="text-[48px] font-semibold leading-[1] text-gray-900 xlg:text-xxl">
            The smarter way to manage ownership
          </h2>
          <span className="text-[16px] font-[450] leading-[1.25] text-gray-900 xlg:text-l">
            Manage share classes, employee plans, and investor relations with ease. Your most
            valuable asset is finally in safe hands.
          </span>
          <div className="flex flex-wrap justify-center gap-6 py-6 lg:flex-col lg:justify-start lg:py-8">
            <div className="flex items-center gap-3">
              <DataIcon className="size-4" />
              <span className="text-base font-[450] text-gray-900">Asset classes management</span>
            </div>
            <div className="flex items-center gap-3">
              <UsersIcon />
              <span className="text-base font-[450] text-gray-900">Employee plan management</span>
            </div>
            <div className="flex items-center gap-3">
              <CheckCircleIcon />
              <span className="text-base font-[450] text-gray-900">Investor onboarding</span>
            </div>
            <div className="flex items-center gap-3">
              <PieChartIcon className="size-4" />
              <span className="text-base font-[450] text-gray-900">Scenario modelling</span>
            </div>
          </div>

          <div className="flex w-full flex-col items-center gap-3 xlg:w-fit xlg:flex-row">
            {/* <a
              className="flex w-full min-w-[148px] max-w-[400px] cursor-pointer items-center justify-center text-nowrap rounded bg-brand-700 px-8 py-[14px] font-inter text-sm font-[500] text-white transition-all duration-300 hover:bg-[#146EF5]"
              href={process.env.REACT_APP_CAP_QUEST_REGISTER_URL}
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Get Started
            </a> */}
            <Button
              className="group relative min-w-[148px] max-w-[400px] gap-[6px]"
              onClick={() => navigate('/contact-sales')}
              styleType="BLUE_ROUNDED"
            >
              Book a demo
              <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2">
                <ArrowRightIcon iconColor="#fff" />
              </span>
            </Button>
          </div>
        </div>
        <div className="flex flex-grow basis-[52%]">
          <HeroAnimatedImage />
        </div>
      </div>
    </section>
  );
};
