export enum SubscriptionFrequency {
  MONTHLY = 'monthly',
  ANNUALY = 'annualy',
}

export enum SubscriptionType {
  START_UP = 'startup',
  GROWTH = 'growth',
  ENTERPRISE = 'enterprise',
}

export const subscriptionTypeTitle: Record<SubscriptionType, string> = {
  [SubscriptionType.START_UP]: 'Start Up',
  [SubscriptionType.GROWTH]: 'Growth',
  [SubscriptionType.ENTERPRISE]: 'Enterprise',
};

export const subscriptionTypeTitleMap = Object.entries(subscriptionTypeTitle);

export type PricingPlan = {
  id: string;
  title: string;
  duration: SubscriptionFrequency;
  type: SubscriptionType | undefined;
  stakeholdersCount: number;
  priceInCents: number;
};

export const listOfSubscriptionBenefits: Record<SubscriptionType, string[]> = {
  [SubscriptionType.START_UP]: [
    'Data Room',
    'Fundraising Tracker',
    'Pools and Plans Management',
    'Cap Table Management',
    'Basic Support',
  ],
  [SubscriptionType.GROWTH]: [
    'Venture Debt Management',
    'Convertible Management',
    'Fundraising Modeling',
    'Liquidity Modeling',
    'Priority Support ',
  ],
  [SubscriptionType.ENTERPRISE]: [
    'Loan Modeling',
    'Event Management',
    'Dashboard Customization',
    'Assisted onboard process',
    'Dedicated Account Manager',
  ],
};

export const subscriptionFrequencyPlanTitle: Record<SubscriptionFrequency, string> = {
  [SubscriptionFrequency.ANNUALY]: 'Billed annually',
  [SubscriptionFrequency.MONTHLY]: 'Billed monthly',
};

export const subscriptionFrequencyBillingTitle: Record<SubscriptionFrequency, string> = {
  [SubscriptionFrequency.ANNUALY]: 'year',
  [SubscriptionFrequency.MONTHLY]: 'month',
};

export const subscriptionTypeBenefitsTitle: Record<SubscriptionType, string> = {
  [SubscriptionType.ENTERPRISE]: 'Includes: Everything in Growth plan +',
  [SubscriptionType.GROWTH]: 'Includes: Everything in Startup plan +',
  [SubscriptionType.START_UP]: 'Includes',
};

export const subscriptionFrequencyTitle: Record<SubscriptionFrequency, string> = {
  [SubscriptionFrequency.MONTHLY]: 'Pay monthly',
  [SubscriptionFrequency.ANNUALY]: '  Pay yearly (Save 17%)',
};
