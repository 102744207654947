import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type DataIconProps = IconProps;

const DataIcon: FC<DataIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2005 22C21.4805 22 21.6206 22 21.7275 21.9455C21.8216 21.8976 21.8981 21.8211 21.946 21.727C22.0005 21.62 22.0005 21.48 22.0005 21.2V10.8C22.0005 10.52 22.0005 10.38 21.946 10.273C21.8981 10.1789 21.8216 10.1024 21.7275 10.0545C21.6206 10 21.4805 10 21.2005 10H18.8005C18.5205 10 18.3804 10 18.2735 10.0545C18.1794 10.1024 18.1029 10.1789 18.055 10.273C18.0005 10.38 18.0005 10.52 18.0005 10.8V13.2C18.0005 13.4801 18.0005 13.62 17.946 13.727C17.898 13.8211 17.8215 13.8976 17.7275 13.9455C17.6205 14 17.4805 14 17.2005 14H14.8005C14.5205 14 14.3805 14 14.2735 14.0545C14.1794 14.1024 14.1029 14.1789 14.055 14.273C14.0005 14.3799 14.0005 14.52 14.0005 14.8V17.2C14.0005 17.48 14.0005 17.6201 13.946 17.727C13.8981 17.8211 13.8216 17.8976 13.7275 17.9455C13.6205 18 13.4805 18 13.2005 18H10.8005C10.5204 18 10.3805 18 10.2735 18.0545C10.1794 18.1025 10.1029 18.1789 10.055 18.273C10.0005 18.38 10.0005 18.52 10.0005 18.8V21.2C10.0005 21.48 10.0005 21.62 10.055 21.727C10.1029 21.8211 10.1794 21.8976 10.2735 21.9455C10.3805 22 10.5204 22 10.8005 22H21.2005Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M10.0005 6.80004C10.0005 6.52002 10.0005 6.37998 10.055 6.273C10.1029 6.17892 10.1794 6.10248 10.2735 6.05454C10.3805 6 10.5204 6 10.8005 6H13.2005C13.4805 6 13.6205 6 13.7275 6.05454C13.8216 6.10248 13.8981 6.17892 13.946 6.273C14.0005 6.37998 14.0005 6.52002 14.0005 6.80004V9.20004C14.0005 9.48006 14.0005 9.62004 13.946 9.72702C13.8981 9.8211 13.8216 9.8976 13.7275 9.94554C13.6205 10 13.4805 10 13.2005 10H10.8005C10.5204 10 10.3805 10 10.2735 9.94554C10.1794 9.8976 10.1029 9.8211 10.055 9.72702C10.0005 9.62004 10.0005 9.48006 10.0005 9.20004V6.80004Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.00049 12.8C3.00049 12.52 3.00049 12.38 3.05499 12.273C3.10292 12.1789 3.17941 12.1025 3.27349 12.0545C3.38045 12 3.52046 12 3.80049 12H6.2005C6.48052 12 6.62056 12 6.72748 12.0545C6.82156 12.1025 6.89806 12.1789 6.946 12.273C7.00048 12.38 7.00048 12.52 7.00048 12.8V15.2C7.00048 15.4801 7.00048 15.62 6.946 15.727C6.89806 15.8211 6.82156 15.8976 6.72748 15.9455C6.62056 16 6.48052 16 6.2005 16H3.80049C3.52046 16 3.38045 16 3.27349 15.9455C3.17941 15.8976 3.10292 15.8211 3.05499 15.727C3.00049 15.62 3.00049 15.4801 3.00049 15.2V12.8Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M2.00049 2.8C2.00049 2.51998 2.00049 2.37996 2.05499 2.27301C2.10293 2.17893 2.17941 2.10244 2.27349 2.0545C2.38045 2 2.52047 2 2.80049 2H5.20049C5.48052 2 5.62053 2 5.72748 2.0545C5.82157 2.10244 5.89806 2.17893 5.94599 2.27301C6.00052 2.37996 6.00052 2.51998 6.00052 2.8V5.2C6.00052 5.48003 6.00052 5.62004 5.94599 5.727C5.89806 5.82108 5.82157 5.89757 5.72748 5.9455C5.62053 5.99998 5.48052 5.99998 5.20049 5.99998H2.80049C2.52047 5.99998 2.38045 5.99998 2.27349 5.9455C2.17941 5.89757 2.10293 5.82108 2.05499 5.727C2.00049 5.62004 2.00049 5.48003 2.00049 5.2V2.8Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DataIcon;
