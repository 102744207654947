import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon, CheckCircleIcon } from '../../assets/icons';
import blueNumbers from '../../assets/images/background/blue-numbers.png';
import eventsImage from '../../assets/images/bottom-events.png';
import shareClassImage from '../../assets/images/bottom-share-class.png';
import Button from '../../components/Button';
interface AnimatedWordProps {
  word: string;
  onAnimationEnd: () => void;
}

const AnimatedWord: React.FC<AnimatedWordProps> = React.memo(({ word, onAnimationEnd }) => {
  const [displayedWord, setDisplayedWord] = useState<string>('');

  useEffect(() => {
    if (!word) return;

    let index = 0;
    setDisplayedWord('');

    const interval = setInterval(() => {
      index += 1;

      if (index <= word.length) {
        setDisplayedWord(word.slice(0, index));
      }

      if (index === word.length) {
        clearInterval(interval);
        onAnimationEnd();
      }
    }, 50);

    return () => clearInterval(interval);
  }, [word, onAnimationEnd]);

  return (
    <span className="absolute left-0 top-[80%] inline-block">
      {displayedWord.split('').map((letter, index) => (
        <span
          key={index}
          style={{
            display: 'inline-block',
            opacity: 0,
            animation: `fallDown 0.3s ease-out ${index * 0.03}s forwards`,
          }}
        >
          {letter === ' ' ? '\u00A0' : letter}
        </span>
      ))}
    </span>
  );
});

const words = [
  'Cap Table',
  'Ownership',
  'ESOPs',
  'Share Classes',
  'Fundraise',
  'Investor Relations',
];

const FinalSection = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const navigate = useNavigate();

  // const redirectToAuth = () =>
  //   window.location.replace(process.env.REACT_APP_CAP_QUEST_REGISTER_URL || '');

  const handleAnimationEnd = useCallback(() => {
    setTimeout(
      () => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      },
      words[currentWordIndex].length * 150 + 2000,
    );
  }, [currentWordIndex]);

  return (
    <section className="relative w-full overflow-hidden bg-[#E6F0FF] px-4 pb-0 pt-12 xlg:p-[72px] xlg:pb-0 lg:p-[84px] lg:pb-0">
      <div className="absolute -left-[271px] -top-[248px] h-[496px] w-[542px] rounded-full bg-[#2565C8] opacity-40 blur-[368px]" />
      <div className="absolute -bottom-[312px] -right-[318px] h-[624px] w-[636px] rounded-full bg-[#2565C8] opacity-40 blur-[476px]" />

      <div className="ml-auto mr-auto flex w-full max-w-[1280px] flex-col items-center gap-16 xlg:gap-[120px]">
        <div className="flex flex-col items-center gap-12 xlg:gap-14">
          <h2 className="relative flex max-w-[435px] pb-2 text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:pb-6 xlg:text-3xl">
            Get control of your{' '}
            <AnimatedWord onAnimationEnd={handleAnimationEnd} word={words[currentWordIndex]} />
          </h2>
          <div className="flex w-full flex-col items-start gap-6 pt-12 xlg:flex-row xlg:gap-12 xlg:pt-14">
            <div className="flex items-center gap-2">
              <CheckCircleIcon className="size-4" />
              <span className="text-base font-[450] text-gray-900">Easy & fast onboarding</span>
            </div>
            <div className="flex items-center gap-2">
              <CheckCircleIcon className="size-4" />
              <span className="text-base font-[450] text-gray-900">Dedicated account manager</span>
            </div>
            <div className="flex items-center gap-2">
              <CheckCircleIcon className="size-4" />
              <span className="text-base font-[450] text-gray-900">Free migration</span>
            </div>
          </div>

          <div className="relative z-10 flex w-full flex-col items-center justify-center gap-6 xlg:flex-row">
            {/* <Button className="w-full xlg:w-auto" onClick={redirectToAuth} styleType="BLUE_ROUNDED">
              Get Started
            </Button> */}
            <Button
              className="group w-full gap-[6px] xlg:w-auto"
              onClick={() => navigate('/contact-sales')}
              styleType="BLUE_ROUNDED"
            >
              Book a demo
              <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2">
                <ArrowRightIcon iconColor="#fff" />
              </span>
            </Button>
          </div>
        </div>
        <div className="relative">
          <div
            className="absolute -top-[80px] left-1/2 h-[167px] w-[960px] -translate-x-1/2 transform bg-no-repeat"
            style={{
              backgroundImage: `url(${blueNumbers})`,
            }}
          />
          <div className="relative z-10">
            <img alt={`Company logo`} className="h-auto w-full max-w-[1024px]" src={eventsImage} />
            <img
              alt={`Company logo`}
              className="absolute -right-[4%] bottom-0 h-auto w-[33%] xlg:-right-[8%]"
              src={shareClassImage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinalSection;
