import { motion, MotionValue, useTransform } from 'framer-motion';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type FeatureCardProps = {
  title: string;
  img: string;
  text: string;
  reversOrder?: boolean;
  progress: MotionValue<number>;
  range: [number, number];
  targetScale: number;
};

export const FeatureCard: FC<FeatureCardProps> = ({
  img,
  text,
  title,
  reversOrder = true,
  progress,
  range,
  targetScale,
}) => {
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <motion.div
      className={twMerge('sticky top-10 bg-white pb-8')}
      style={{ scale }}
      transition={{
        scale: {
          duration: 0.5,
          ease: 'easeInOut',
        },
      }}
    >
      <div
        className={twMerge(
          'flex h-full w-full flex-wrap gap-8 rounded-lg border border-[#EAECF0] bg-white',
          reversOrder && 'flex-row-reverse',
        )}
      >
        <div
          className={twMerge(
            'flex w-full min-w-[340px] flex-1 basis-[400px] flex-col gap-8 px-6 pt-6 text-start lg:px-14 lg:pt-14',
            reversOrder && 'lg:text-end',
          )}
        >
          <span className="text-[32px] font-semibold leading-[38px] text-[#101828]">{title}</span>
          <span className="text-sm text-[#101828]">{text}</span>
        </div>
        <div className="flex h-[487px] w-full flex-grow basis-[500px] items-end justify-center bg-[#F2F4F7] px-[50px] pt-[50px] max-md:h-[390px] max-xs:h-[326px] max-xs:px-6 max-xs:pt-6">
          <img className="w-full max-w-[500px] object-contain" src={img} />
        </div>
      </div>
    </motion.div>
  );
};
