import { FC } from 'react';

import { CheckCircleIcon, DataIcon, PieChartIcon, UsersIcon } from '../../assets/icons';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { ContactForm } from './ContactForm';

export const ContactSales: FC = () => {
  return (
    <div className="relative text-center font-inter text-sm font-normal text-gray-700">
      <div className="relative flex min-h-full w-full flex-col overflow-hidden bg-[#F9FAFB]">
        <Header className="bg-transparent" />
        <div className="absolute -left-[271px] -top-[248px] h-[496px] w-[542px] rounded-full bg-[#98A2B3] opacity-100 blur-[368px]" />
        <section className="relative ml-auto mr-auto flex w-full max-w-[1280px] flex-col items-center gap-8 px-4 py-[86px] xlg:flex-row xlg:items-start xlg:justify-between xlg:gap-3 xlg:px-10">
          <div className="flex max-w-full flex-col items-center gap-4 text-center xlg:max-w-[621px]">
            <h2 className="text-xxl font-semibold text-gray-900">Contact Sales</h2>
            <span className="max-w-[533px] text-base text-gray-600">
              Manage share classes, employee plans, and investor relations with ease. Your most
              valuable asset is finally in safe hands.
            </span>
            <div className="flex max-w-[451px] flex-wrap items-center justify-center gap-6 border-t-[1px] border-[#1018280F] pb-4 pt-8">
              <div className="flex items-center gap-3">
                <DataIcon className="size-4" iconColor="#475467" />
                <span className="text-sm font-[450] text-gray-600">Share classes management</span>
              </div>
              <div className="flex items-center gap-3">
                <UsersIcon iconColor="#475467" />
                <span className="text-sm font-[450] text-gray-600">Employee plan management</span>
              </div>
              <div className="flex items-center gap-3">
                <CheckCircleIcon iconColor="#475467" />
                <span className="text-sm font-[450] text-gray-600">Investor onboarding</span>
              </div>
              <div className="flex items-center gap-3">
                <PieChartIcon className="size-4" iconColor="#475467" />
                <span className="text-sm font-[450] text-gray-600">Scenario modelling</span>
              </div>
            </div>

            {/* <div className="hidden flex-col items-center justify-center gap-8 border-t-[1px] border-[#1018280F] pt-8 xlg:flex">
            <span className="text-xs font-[450] text-gray-600">
              Trusted by leading companies and partners from around the world
            </span>
            <div className="flex max-w-[480px] flex-wrap items-center justify-center gap-4">
              {companies.slice(0, 6).map((company, index) => (
                <div className="flex h-[42px] w-[129px] items-center justify-center" key={index}>
                  <img
                    alt={`Company ${index}`}
                    className="h-auto max-h-full max-w-none"
                    src={company}
                    style={{
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                </div>
              ))}
            </div>
          </div> */}

            {/* <div className="flex w-full flex-col items-center gap-8 border-y-[1px] border-[#1018280F] py-8 xlg:hidden">
            <span className="text-xs font-[450] text-gray-600">
              Trusted by leading companies and partners from around the world
            </span>
            <CompanySlider />
          </div> */}
          </div>
          <div className="rounded-lg bg-white p-4 shadow-2xl">
            <ContactForm />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};
