import React, { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, SearchIcon } from '../../assets/icons';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  InputStatus,
} from '../Combobox';
import { Flag, FlagCode } from '../Flag';
import Input from '../Input/Input';
import { Country } from './types';

export type PhoneCountry = {
  prefix: string;
  name: string;
  iso2Code: string;
};

export type PhoneNumberItemsListProps = {
  data: Country[];
  isLoading: boolean;
  isFetching: boolean;
};

const PhoneNumberItemsList: FC<PhoneNumberItemsListProps> = ({ data, isFetching, isLoading }) => {
  if (!data || isFetching || isLoading) return <span>Loading ...</span>;

  return (
    <>
      {data.map(({ name, callingCode, iso3, iso2 }) => (
        <ComboboxOption
          key={`${iso3}_${iso2}`}
          value={
            {
              iso2Code: iso2,
              prefix: callingCode,
              name,
            } as unknown as string
          }
        >
          <Flag countryCode={iso2} />
          <span className="text-sm text-gray-700">
            {name} {callingCode}
          </span>
        </ComboboxOption>
      ))}
    </>
  );
};

export type PhoneNumberComboboxProps = {
  onSelectCountry?: (country: PhoneCountry) => void;
  onPhoneChange?: (number: string) => void;
  disabled?: boolean;
  selectedPhoneNumber: {
    country: PhoneCountry | undefined;
    number: string;
  };
};

const PhoneNumberCombobox: FC<PhoneNumberComboboxProps> = ({
  onPhoneChange,
  onSelectCountry,
  disabled,
  selectedPhoneNumber,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<{
    country: PhoneCountry;
    phone: string;
  }>({
    country: { iso2Code: '', name: '', prefix: '' },
    phone: '',
  });

  const [search, setSearch] = useState('');
  const [isFocused, setFocused] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const {
    data: phoneCountriesData = [],
    isLoading,
    isFetching,
  } = useQuery<Country[]>(['countries', search], {
    queryFn: async () => {
      const params = new URLSearchParams();

      if (search) {
        params.append('search', search);
      }

      const response = await fetch(`${backendUrl}/countries?${params.toString()}`, {
        headers: { 'Content-Type': 'application/json' },
      });

      return response.json();
    },
  });

  useEffect(() => {
    if (!phoneCountriesData) return;
    let selectedPhoneCountry = phoneCountriesData?.find(
      ({ callingCode }) =>
        selectedPhoneNumber.number.startsWith("'" + callingCode) ||
        selectedPhoneNumber.number.startsWith(callingCode),
    );
    const selectedCountryByIso2 = phoneCountriesData?.find(
      ({ iso2 }) => selectedPhoneNumber.country?.iso2Code === iso2,
    );

    selectedPhoneCountry = selectedCountryByIso2 || selectedPhoneCountry;

    if (!selectedPhoneCountry) return;

    setPhoneNumber((prev) => ({
      ...prev,
      phone: selectedPhoneNumber.number.split(selectedPhoneCountry?.callingCode || '')[1] || '',
      country: {
        iso2Code: selectedPhoneCountry?.iso2 || '',
        name: selectedPhoneCountry?.name || '',
        prefix: selectedPhoneCountry?.callingCode || '',
      },
    }));

    onPhoneChange?.(selectedPhoneNumber.number.split(selectedPhoneCountry.callingCode || '')[1]);
    onSelectCountry?.({
      iso2Code: selectedPhoneCountry.iso2,
      name: selectedPhoneCountry.name,
      prefix: selectedPhoneCountry.callingCode,
    });
  }, []);

  const isSelectedCountry = phoneNumber.country.name;
  const ref = useRef<HTMLInputElement>(null);
  return (
    <Combobox
      as={'div'}
      className="relative"
      disabled={disabled}
      name={'phoneCountry'}
      onChange={(data: PhoneCountry) => {
        setSearch('');
        setPhoneNumber((prev) => ({ ...prev, country: data }));

        onSelectCountry?.(data);
      }}
      onClose={() => setSearch('')}
    >
      {() => (
        <>
          <div className="relative flex h-full w-full justify-between bg-gray-50">
            <div className="relative h-full w-[240px] shrink-0">
              <ComboboxInput
                customStatus={isFocused ? InputStatus.FOCUSED : InputStatus.IDLE_EMPTY}
                icon={
                  <ChevronDownIcon
                    className={twMerge(isSelectedCountry ? 'mt-3' : 'mt-0', disabled && 'hidden')}
                  />
                }
                iconBeforeInput={
                  <Flag className="mt-5" countryCode={phoneNumber?.country?.iso2Code as FlagCode} />
                }
                onBlur={() => setFocused(false)}
                onChange={() => ''}
                onFocus={() => setFocused(true)}
                placeholder="Phone number (optional)"
                readOnly
                value={
                  isSelectedCountry
                    ? `${phoneNumber.country?.name} ${phoneNumber?.country?.prefix}`
                    : ''
                }
                wrapperClassName={twMerge('w-full', disabled && 'border-none')}
              />
              <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
            </div>

            <Input
              customStatus={isFocused ? InputStatus.FOCUSED : InputStatus.IDLE_EMPTY}
              disabled={disabled}
              onBlur={() => setFocused(false)}
              onChange={(phone) => {
                setPhoneNumber((prev) => ({ ...prev, phone }));
                onPhoneChange?.(phone);
              }}
              onFocus={() => setFocused(true)}
              value={phoneNumber?.phone || ''}
              wrapperClassName="w-full"
            />
          </div>
          <ComboboxOptions className="w-full">
            <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
              <SearchIcon className="absolute ml-[6px]" />
              <input
                className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
                onChange={({ target: { value } }) => setSearch(value)}
                ref={ref}
                value={search}
              />
            </div>

            <PhoneNumberItemsList
              data={phoneCountriesData || []}
              isFetching={isFetching}
              isLoading={isLoading}
            />
          </ComboboxOptions>
        </>
      )}
    </Combobox>
  );
};

export default PhoneNumberCombobox;
