import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CursorIconProps = IconProps;

const CursorIcon: FC<CursorIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0202 17.9841C11.2201 18.4982 11.3201 18.7553 11.4614 18.8274C11.5839 18.89 11.7293 18.8881 11.8501 18.8223C11.9895 18.7465 12.0826 18.4869 12.269 17.9678L17.0223 4.72636C17.1748 4.30161 17.2511 4.08924 17.203 3.9504C17.1612 3.82969 17.0663 3.73483 16.9456 3.69303C16.8068 3.64495 16.5944 3.72118 16.1696 3.87365L2.92821 8.62695C2.40904 8.81332 2.14946 8.9065 2.07361 9.04589C2.0079 9.16666 2.00599 9.31207 2.06851 9.43452C2.14068 9.57586 2.39772 9.67582 2.91182 9.87575L8.47647 12.0398C8.57718 12.0789 8.62753 12.0985 8.66993 12.1288C8.70751 12.1556 8.74038 12.1884 8.76718 12.226C8.79743 12.2684 8.81701 12.3188 8.85617 12.4195L11.0202 17.9841Z"
        fill={iconColor || '#F79009'}
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CursorIcon;
