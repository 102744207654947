import React from 'react';

const TickIcon = () => {
  return (
    <svg fill="none" height="56" viewBox="0 0 57 56" width="57" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.833 25.8536V28.0002C51.8301 33.0319 50.2008 37.9278 47.1881 41.9578C44.1754 45.9879 39.9406 48.936 35.1155 50.3627C30.2903 51.7893 25.1333 51.618 20.4134 49.8743C15.6936 48.1305 11.6638 44.9078 8.92523 40.6867C6.18661 36.4657 4.88583 31.4724 5.2169 26.4516C5.54797 21.4309 7.49314 16.6517 10.7623 12.8268C14.0315 9.00183 18.4495 6.33614 23.3574 5.22726C28.2654 4.11838 33.4003 4.62571 37.9963 6.67359"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M51.8333 9.33447L28.4999 32.6911L21.4999 25.6911"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export default TickIcon;
