import { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { twMerge } from 'tailwind-merge';

import blueLines from '../../assets/images/background/blue-lines.png';
import Advantages from '../../components/Advantages/Advantages';
import FinalSection from '../../components/FinalSection/FinalSection';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { Hero } from '../../components/Hero';
import ToolsTabs from '../../components/ToolsTabs/ToolsTabs';

export const Home = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative text-center font-inter text-sm font-normal text-gray-700">
      <div
        className={twMerge(
          isHovered &&
            `absolute inset-0 z-2 bg-[linear-gradient(180deg,rgba(37,101,200,0.06)0%,rgba(2,8,17,0.36)100%)] backdrop-blur-[8px] transition-all duration-300`,
        )}
        style={isHovered ? { pointerEvents: 'none' } : {}}
      />

      <main className="flex flex-col items-center transition-all duration-300">
        <div className="relative w-full max-w-full overflow-hidden bg-[#E6F0FF]">
          <div className="absolute -left-[271px] -top-[248px] h-[496px] w-[542px] rounded-full bg-[#2565C8] opacity-40 blur-[368px]" />
          <div className="absolute right-[432px] top-[172px] h-[87px] w-[174px] rounded-full bg-[#2565C8] opacity-100 blur-[124px]" />
          <div className="absolute -bottom-[205px] -right-[440px] h-[87px] w-[174px] rounded-full bg-[#2565C8] opacity-80 blur-[124px]" />
          <div className="absolute -bottom-[312px] -right-[318px] hidden h-[624px] w-[636px] rounded-full bg-[#2565C8] opacity-40 blur-[476px] lg:block" />
          <div
            className="absolute right-[268px] top-[69px] hidden h-[86px] w-[336px] rotate-180 bg-no-repeat lg:block"
            style={{
              backgroundImage: `url(${blueLines})`,
            }}
          />
          <Header onHoverChange={setIsHovered} />

          <Hero />
        </div>
        {/* <section className="flex w-full flex-col gap-12 py-14">
          <span className="text-base text-gray-600">
            Trusted by leading companies and partners from around the world
          </span>
          <CompanySlider />
        </section> */}
        <ToolsTabs />
        {/* <Reviews /> */}
        <Advantages />
        <FinalSection />
      </main>
      <Footer />
    </div>
  );
};
