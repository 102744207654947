import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CoinsStackedIcon,
  DataIcon,
  HeartIcon,
  LightningIcon,
  PieChartIcon,
} from '../../assets/icons';
import capTable from '../../assets/images/cap-table.png';
import capTableMini from '../../assets/images/cap-table-mini.png';
import convertibleBlade from '../../assets/images/convertible-blade.png';
import equityBlade from '../../assets/images/equity-blade.png';
import eventBlade from '../../assets/images/event-blade.png';
import exerciseBlade from '../../assets/images/exercise-blade.png';
import fundraisingBlade from '../../assets/images/fundraising-blade.png';
import investmentBlade from '../../assets/images/investment-blade.png';
import ownershipBlade from '../../assets/images/ownership-blade.png';
import planBlade from '../../assets/images/plan-blade.png';
import safeBlade from '../../assets/images/safe-blade.png';
import scenarioDetails from '../../assets/images/scenario-details.png';
import scenarioDetailsMini from '../../assets/images/scenario-details-mini.png';
import { SolutionVariant } from '../../pages/Solutions/types';
import Button from '../Button';
import { Tab } from './Tab';

enum ToolsTab {
  ESOP = 'esop',
  FINANCIAL = 'financial',
  CAP_TABLE = 'cap-table',
  INVESTOR = 'investor',
  SCENARIO = 'scenario',
}

const ToolsTabData: Record<ToolsTab, { title: string; icon: JSX.Element }> = {
  [ToolsTab.ESOP]: { title: 'ESOP Management', icon: <DataIcon /> },
  [ToolsTab.FINANCIAL]: { title: 'Financial Instruments', icon: <LightningIcon /> },
  [ToolsTab.CAP_TABLE]: { title: 'Cap Table Mgmt', icon: <CoinsStackedIcon /> },
  [ToolsTab.INVESTOR]: { title: 'Investor Relations', icon: <HeartIcon /> },
  [ToolsTab.SCENARIO]: { title: 'Scenario Modeling', icon: <PieChartIcon /> },
};

const tabVariant = Object.values(ToolsTab);

const ToolsTabs: React.FC = () => {
  const [selectedTab, setTab] = useState<ToolsTab>(ToolsTab.ESOP);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <section className="flex w-full max-w-[1380px] flex-col items-center justify-center gap-16 overflow-hidden px-4 py-[84px] xlg:px-[50px]">
      <div className="flex flex-col items-center gap-4">
        <h2 className="text-3xl font-semibold text-gray-900">
          Expert tools to supercharge your equity strategy
        </h2>
        <span className="max-w-[942px] text-base text-gray-600">
          CapQuest streamlines cap table management, equity allocation, and debt tracking,
          empowering founders with real-time insights and scenario simulations to navigate
          ownership, facilitate fundraising and measure dilution impacts.
        </span>
      </div>
      <div className="w-full overflow-x-auto whitespace-nowrap">
        <div className="flex w-full min-w-max items-center justify-center gap-4">
          {tabVariant.map((variant) => (
            <Button
              className={twMerge(
                'h-full w-fit rounded bg-gray-50 p-3 text-sm font-[500] text-gray-900',
                selectedTab === variant && 'bg-[#3977D7] font-[500] text-white',
              )}
              key={variant}
              onClick={() => {
                setTab(variant);
              }}
              styleType="NONE"
            >
              <div className="flex items-center gap-2">
                {React.cloneElement(ToolsTabData[variant].icon, {
                  iconColor: selectedTab === variant ? '#ffffff' : '#101828',
                })}
                {ToolsTabData[variant].title}
              </div>
            </Button>
          ))}
        </div>
      </div>
      {selectedTab === ToolsTab.ESOP && (
        <Tab
          className="lg:ml-[88px]"
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          navigateState={SolutionVariant.EQUITY_PLAN_AUTOMATION}
          points={[
            'Employee Engagement',
            'Employee Dashboard',
            'Automated Vesting Structures ',
            'Comprehensive Reminders',
          ]}
          subtitle="Eliminate paperwork and errors with our automated equity management —streamline grants,
            exercises, and vesting for a seamless stakeholder experience."
          title="Equity Plan Automation"
        >
          <>
            <img
              alt="Event Blade"
              className="z-100 absolute bottom-[32px] right-[24px] w-[300px] lg:right-[108px] lg:w-[404px]"
              src={eventBlade}
              style={{
                transform: hovered ? 'translateY(-60px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt="Exercise Blade"
              className="z-100 absolute bottom-[16px] right-[12px] w-[312px] lg:right-[100px] lg:w-[420px]"
              src={exerciseBlade}
              style={{
                transform: hovered ? 'translateY(-30px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt="Plan Blade"
              className="z-100 absolute bottom-0 right-0 w-[329px] lg:right-[88px] lg:w-[444px]"
              src={planBlade}
            />
          </>
        </Tab>
      )}
      {selectedTab === ToolsTab.FINANCIAL && (
        <Tab
          className="lg:ml-[88px]"
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          navigateState={SolutionVariant.OPTIMIZED_FUNDRAISING_INSTRUMENTS}
          points={[
            'Share Class Management',
            'Automated SAFE Conversion',
            'Flexible Convertibles',
            'Venture Debt Management',
          ]}
          subtitle="Build your fundraising structure with confidence — set up SAFEs, Convertibles, priced rounds, Venture Debt and any share class with complete flexibility."
          title="Optimized Fundraising Instruments"
        >
          <>
            <img
              alt={`Cap Table`}
              className="absolute bottom-[30px] right-[24px] w-[300px] lg:right-[108px] lg:w-[404px]"
              src={safeBlade}
              style={{
                transform: hovered ? 'translateY(-60px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt={`Cap Table`}
              className="absolute bottom-[18px] right-[12px] w-[312px] lg:right-[100px] lg:w-[420px]"
              src={convertibleBlade}
              style={{
                transform: hovered ? 'translateY(-30px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt={`Cap Table`}
              className="absolute bottom-0 right-0 w-[329px] lg:right-[88px] lg:w-[444px]"
              src={fundraisingBlade}
            />
          </>
        </Tab>
      )}
      {selectedTab === ToolsTab.CAP_TABLE && (
        <Tab
          className="h-[180px] lg:min-h-[260px]"
          navigateState={SolutionVariant.SIMPLIFIED_CAP_TABLE}
          points={[
            'Ownership Breakdown',
            'Complete Transaction History',
            'Multi-Currency & Multi-Jurisdiction Support',
            'Customized Views',
          ]}
          subtitle="Streamline equity, SAFEs, Convertibles, employee plans, and venture debt — 
all in one place for a stronger, transparent and efficient governance."
          title="Simplified Cap Table Management"
        >
          <img
            alt={`Cap Table`}
            className="absolute bottom-0 right-0 w-[600px] rounded-b-lg"
            src={capTable}
          />
          <img
            alt={`Cap Table`}
            className="absolute bottom-0 right-[0.1%] h-auto w-[55%]"
            src={capTableMini}
          />
        </Tab>
      )}
      {selectedTab === ToolsTab.INVESTOR && (
        <Tab
          className="h-[293px] lg:min-h-[420px]"
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          navigateState={SolutionVariant.ENHANCED_INVESTOR_RELATIONS}
          points={[
            'Personalized Investor Dashboard',
            'Fundraising & Exit Scenario Modelling',
            'Customizable MIS & KPI Reporting',
            'Automated Updates',
          ]}
          subtitle="Build trust with real-time transparent equity insights and empower
investors with tools to model clear exit scenarios."
          title="Keep Investors Engaged and Informed"
        >
          <>
            <img
              alt={`Cap Table`}
              className="absolute bottom-0 right-0 w-[443px] lg:right-10"
              src={investmentBlade}
              style={{
                transform: hovered ? 'translateY(-30px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt={`Cap Table`}
              className="absolute bottom-0 right-0 w-[443px] lg:right-10"
              src={equityBlade}
              style={{
                transform: hovered ? 'translateY(-15px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <img
              alt={`Cap Table`}
              className="absolute bottom-0 right-0 w-[443px] lg:right-10"
              src={ownershipBlade}
            />
          </>
        </Tab>
      )}
      {selectedTab === ToolsTab.SCENARIO && (
        <Tab
          className="h-[180px] lg:min-h-[260px]"
          navigateState={SolutionVariant.EFFORTLESS_SCENARIO_MODELING}
          points={[
            'Precise Calculations, Instantly Shareable',
            'Fundraising Round Scenario',
            'Exit Scenario',
            'Debt & Venture Debt Scenario',
          ]}
          subtitle="Visualize ownership shifts and anticipate your exit — 
get real-time future insights for founders and investors alike."
          title="Effortless Scenario Modelling"
        >
          <img
            alt={`Scenario details`}
            className="absolute bottom-0 right-0 w-[600px] rounded-b-lg"
            src={scenarioDetails}
          />

          <img
            alt={`Cap Table`}
            className="absolute bottom-0 right-[0.1%] h-auto w-[50%]"
            src={scenarioDetailsMini}
          />
        </Tab>
      )}
    </section>
  );
};

export default ToolsTabs;
