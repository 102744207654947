import React from 'react';

import { Angle } from '../../assets/icons';
import blueLines from '../../assets/images/background/blue-lines.png';
import blueNumbers from '../../assets/images/background/blue-numbers.png';
import bottomGrayDots from '../../assets/images/background/bottom-gray-dots.png';
import grayDots from '../../assets/images/background/gray-dots.png';
import employeesImage from '../../assets/images/employee-advantage.png';
import foundersImage from '../../assets/images/founders-advantage.png';
import investorsImage from '../../assets/images/investor-advantage.png';
import AutoSwitchMessages from '../AutoSwitchMessages/AutoSwitchMessages';

const founderMessages = [
  {
    text: 'Cap tables can turn into a maze... fast. Our software (and service) is built to untangle the chaos and bring clarity to all aspects of equity management, giving you full control and visibility—without the headaches.',
    title: 'Cap Table - Simplified, Organized',
  },
  {
    text: '"Vesting schedules," "exercisable grants," "share class conversion ratios". As fun as these are, we’re guessing you’d prefer to spend less time juggling all that. Our automated workflows handle the complexity so you don’t have to, making equity management refreshingly simple',
    title: 'Automated and Efficient',
  },
  {
    text: 'Planning future rounds? Worried about dilution? How do you properly convert those SAFEs? You don’t need a crystal ball—just our scenario modeling tool. We make it easy to predict how fundraising, exits, convertibles and new investors will shape your cap table. ',
    title: `Scenario Modeling - Made Less Scary`,
  },
];
const employeeMessages = [
  {
    text: 'Because stock options shouldn’t feel like solving a mystery. We make sure employees know exactly what’s theirs— with clear information about options and ownership plans, ensuring no one is left confused.',
    title: 'Onboarding Without the Headache',
  },
  {
    text: 'Employees get their own personalized dashboard to track equity, vesting, and future payouts. Finally, clarity without the spreadsheets - because everyone loves a little extra motivation with their morning coffee.',
    title: 'Real-Time Equity Insight',
  },
  {
    text: 'We break down the fine print of equity like that cool teacher who made math fun. With our easy-to-digest resources and reports, employees will actually get what their stock means—no jargon, no guessing, just real value.',
    title: `Empowerment Through Knowledge`,
  },
];
const investorMessages = [
  {
    text: 'Transparent access to cap table data, equity positions, and portfolio performance, allowing them to see where they stand at any moment. Instant visibility, minus the “I’ll get back to you in a week” emails',
    title: 'Clear Equity Visibility',
  },
  {
    text: 'Want to know what happens when you pour more money in or when that sweet exit finally comes? Our custom scenarios let investors play fortune teller - but with actual data. No magic, just smart decisions.',
    title: 'Liquidity and Exit Insights',
  },
  {
    text: 'CapQuest keeps investors in the loop with real-time access to the latest reports and documents, streamlining communication and cutting down on the need for constant follow-ups',
    title: `Streamlined communication`,
  },
];

const Advantages: React.FC = () => {
  return (
    <section className="flex w-full flex-col">
      <div className="ml-auto mr-auto flex w-full max-w-[1280px] flex-col gap-[48px] px-4 py-[48px] xlg:gap-[72px] xlg:px-6 xlg:py-[72px] lg:gap-[84px] lg:py-[84px]">
        <div className="flex flex-col items-center gap-4">
          <h2 className="max-w-[687px] text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:text-3xl">
            Why founders love CapQuest
          </h2>
          <span className="max-w-[648px] text-base text-gray-600">
            Loved by founders, CapQuest makes cap table management simple and automates equity for
            clear, smart decision-making.
          </span>
        </div>
        <div className="flex h-max flex-wrap items-center gap-12 xlg:pl-10 xlg:pr-4 lg:flex-nowrap">
          <AutoSwitchMessages messages={founderMessages} />
          <div className="relative flex min-h-full flex-grow basis-[50%] justify-center overflow-hidden rounded-br-xl rounded-tl-xl border-[1px] border-[#2565C833] bg-[#FFFBFA]">
            <Angle className="absolute bottom-0 left-0" />
            <Angle className="absolute right-0 top-0 rotate-180" />
            <div className="absolute bottom-[65px] left-[39px] h-[432px] w-[472px] rounded-full bg-[#2565C8] opacity-40 blur-[368px]" />
            <div
              className="absolute left-1/2 top-0 h-[147px] w-[683px] -translate-x-1/2 transform bg-no-repeat"
              style={{
                backgroundImage: `url(${grayDots})`,
              }}
            />
            <div
              className="absolute -bottom-[80px] left-1/2 h-[147px] w-[683px] -translate-x-1/2 transform bg-no-repeat"
              style={{
                backgroundImage: `url(${bottomGrayDots})`,
              }}
            />
            <div className='z-10" relative px-[20px] pt-[30px] xlg:px-[45px] xlg:pb-[20px] xlg:pt-[55px]'>
              <img alt={`Founders advantage`} className="w-[502px]" src={foundersImage} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="h-full w-full border-t-[1px] border-[#10182814]"
        style={{
          background:
            'radial-gradient(37.94% 60.34% at 50.36% 38.25%, rgba(152, 162, 179, 0) 0%, rgba(249, 250, 251, 0.28) 100%)',
        }}
      >
        <div className="ml-auto mr-auto flex w-full max-w-[1280px] flex-col gap-[48px] px-4 py-[48px] xlg:gap-[72px] xlg:px-6 xlg:py-[72px] lg:gap-[84px] lg:py-[84px]">
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:text-3xl">
              Why employees love CapQuest
            </h2>
            <span className="max-w-[648px] text-base text-gray-600">
              With CapQuest, employees enjoy seamless onboarding, instant equity insights, and a
              strong sense of long-term value.
            </span>
          </div>
          <div className="flex h-max flex-wrap-reverse items-center justify-end gap-12 xlg:pl-4 xlg:pr-9 lg:flex-row lg:flex-nowrap">
            <div className="relative flex min-h-full flex-grow basis-[50%] justify-center overflow-hidden rounded-bl-xl rounded-tr-xl border-[1px] border-[#2565C833] bg-[#FBFCFF]">
              <Angle className="absolute bottom-0 right-0 -rotate-90" />
              <Angle className="absolute left-0 top-0 rotate-90" />
              <div className="absolute bottom-[65px] left-[39px] h-[432px] w-[472px] rounded-full bg-[#2565C8] opacity-40 blur-[368px]" />
              <div
                className="absolute bottom-0 left-1/2 h-[86px] w-[336px] -translate-x-1/2 transform bg-no-repeat"
                style={{
                  backgroundImage: `url(${blueLines})`,
                }}
              />
              <div className='z-10" relative px-[20px] pt-[35px] xlg:px-[35px] xlg:pb-[20px] xlg:pt-[50px]'>
                <img alt={`Founders advantage`} className="w-[502px]" src={employeesImage} />
              </div>
            </div>
            <AutoSwitchMessages messages={employeeMessages} side="RIGHT" />
          </div>
        </div>
      </div>

      <div className="h-full w-full overflow-hidden bg-[#020811]">
        <div className="relative ml-auto mr-auto flex w-full max-w-[1280px] flex-col gap-[48px] px-4 py-[48px] xlg:gap-[72px] xlg:px-6 xlg:py-[72px] lg:gap-[84px] lg:py-[84px]">
          <div className="absolute -top-[216px] left-1/2 h-[432px] w-[472px] -translate-x-1/2 transform rounded-full bg-[#2565C8] opacity-50 blur-[368px]" />
          <div className="flex flex-col items-center gap-4">
            <h2 className="max-w-[687px] text-[32px] font-semibold leading-[1.25] text-white xlg:text-3xl">
              Why investors love CapQuest
            </h2>
            <span className="max-w-[648px] text-base text-[#C6C6C6]">
              CapQuest keeps investors connected with transparent cap table data and tailored
              insights, empowering informed investment choices.
            </span>
          </div>
          <div className="flex h-max flex-wrap items-center gap-12 xlg:pl-9 xlg:pr-4 lg:flex-nowrap">
            <AutoSwitchMessages messages={investorMessages} variant="DARK" />
            <div className="relative flex min-h-full flex-grow basis-[50%] justify-center overflow-hidden rounded-br-xl rounded-tl-xl border-[1px] border-[#DDD4F233] bg-[#0A0613]">
              <Angle className="absolute bottom-0 left-0" iconColor="#C9B3FD" />
              <Angle className="absolute right-0 top-0 rotate-180" iconColor="#C9B3FD" />
              <div className="absolute -top-[216px] left-[112px] h-[432px] w-[472px] rounded-full bg-[#2565C8] opacity-50 blur-[368px]" />
              <div
                className="absolute -bottom-[50px] left-1/2 h-[167px] w-[960px] -translate-x-1/2 transform bg-no-repeat"
                style={{
                  backgroundImage: `url(${blueNumbers})`,
                }}
              />
              <div className='z-10" relative px-[41px] py-[52px] xlg:px-[60px] xlg:py-[55px]'>
                <img alt={`Founders advantage`} className="w-[502px]" src={investorsImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
