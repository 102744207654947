import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CheckVerifiedIconProps = IconProps;

const CheckVerifiedIcon: FC<CheckVerifiedIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00016 8.00016L7.3335 9.3335L10.3335 6.3335M11.9343 3.3325C12.0716 3.66452 12.3351 3.92843 12.6669 4.06621L13.8303 4.54815C14.1624 4.68568 14.4261 4.94949 14.5637 5.28154C14.7012 5.61358 14.7012 5.98666 14.5637 6.3187L14.0821 7.48136C13.9445 7.81355 13.9443 8.18701 14.0825 8.51904L14.5633 9.68136C14.6315 9.84582 14.6666 10.0221 14.6666 10.2001C14.6666 10.3782 14.6316 10.5544 14.5634 10.7189C14.4953 10.8834 14.3955 11.0328 14.2696 11.1587C14.1436 11.2846 13.9942 11.3844 13.8297 11.4525L12.6671 11.9341C12.3351 12.0714 12.0712 12.3349 11.9334 12.6667L11.4515 13.8302C11.3139 14.1622 11.0501 14.426 10.7181 14.5636C10.3861 14.7011 10.013 14.7011 9.68097 14.5636L8.51835 14.082C8.18631 13.9448 7.81338 13.9451 7.48155 14.0828L6.31809 14.564C5.98624 14.7012 5.6135 14.7011 5.28174 14.5637C4.94998 14.4263 4.68633 14.1628 4.5487 13.8311L4.06664 12.6672C3.92936 12.3352 3.66586 12.0713 3.33406 11.9335L2.17061 11.4516C1.83872 11.3141 1.575 11.0505 1.43742 10.7186C1.29984 10.3868 1.29965 10.0138 1.4369 9.68186L1.91847 8.5192C2.05567 8.18714 2.05539 7.8142 1.91769 7.48235L1.43681 6.31802C1.36863 6.15356 1.33352 5.97728 1.3335 5.79925C1.33347 5.62122 1.36852 5.44493 1.43664 5.28045C1.50477 5.11597 1.60464 4.96653 1.73054 4.84066C1.85644 4.7148 2.00591 4.61498 2.17041 4.5469L3.33303 4.06531C3.66474 3.92814 3.92849 3.66496 4.06637 3.33353L4.54828 2.17004C4.68582 1.83799 4.94961 1.57419 5.28164 1.43665C5.61368 1.29911 5.98674 1.29911 6.31877 1.43665L7.48139 1.91824C7.81343 2.05544 8.18637 2.05516 8.5182 1.91746L9.68214 1.4374C10.0141 1.29994 10.3871 1.29996 10.7191 1.43747C11.051 1.57498 11.3148 1.83872 11.4524 2.17067L11.9344 3.33452L11.9343 3.3325Z"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export default CheckVerifiedIcon;
