import effortlessScenarioModelingBase from '../../assets/images/solutions/effortlessScenarioModeling/base.png';
import effortlessScenarioModelingFeature1 from '../../assets/images/solutions/effortlessScenarioModeling/feature1.png';
import effortlessScenarioModelingFeature2 from '../../assets/images/solutions/effortlessScenarioModeling/feature2.png';
import effortlessScenarioModelingFeature3 from '../../assets/images/solutions/effortlessScenarioModeling/feature3.png';
import effortlessScenarioModelingFeature4 from '../../assets/images/solutions/effortlessScenarioModeling/feature4.png';
import enhancedInvestorsRelationsBase from '../../assets/images/solutions/enhancedInvestorsRelations/base.png';
import enhancedInvestorsRelationsFeature1 from '../../assets/images/solutions/enhancedInvestorsRelations/feature1.png';
import enhancedInvestorsRelationsFeature2 from '../../assets/images/solutions/enhancedInvestorsRelations/feature2.png';
import enhancedInvestorsRelationsFeature3 from '../../assets/images/solutions/enhancedInvestorsRelations/feature3.png';
import enhancedInvestorsRelationsFeature4 from '../../assets/images/solutions/enhancedInvestorsRelations/feature4.png';
import enhancedInvestorsRelationsFeature5 from '../../assets/images/solutions/enhancedInvestorsRelations/feature5.png';
import equityPlanAutomationBase from '../../assets/images/solutions/equityPlanAutomation/base.png';
import equityPlanAutomationFeature1 from '../../assets/images/solutions/equityPlanAutomation/feature1.png';
import equityPlanAutomationFeature2 from '../../assets/images/solutions/equityPlanAutomation/feature2.png';
import equityPlanAutomationFeature3 from '../../assets/images/solutions/equityPlanAutomation/feature3.png';
import equityPlanAutomationFeature4 from '../../assets/images/solutions/equityPlanAutomation/feature4.png';
import equityPlanAutomationFeature5 from '../../assets/images/solutions/equityPlanAutomation/feature5.png';
import optimizedFundraisingInstrumentsBase from '../../assets/images/solutions/optimizedFundraisingInstruments/base.png';
import optimizedFundraisingInstrumentsFeature1 from '../../assets/images/solutions/optimizedFundraisingInstruments/feature1.png';
import optimizedFundraisingInstrumentsFeature2 from '../../assets/images/solutions/optimizedFundraisingInstruments/feature2.png';
import optimizedFundraisingInstrumentsFeature3 from '../../assets/images/solutions/optimizedFundraisingInstruments/feature3.png';
import optimizedFundraisingInstrumentsFeature4 from '../../assets/images/solutions/optimizedFundraisingInstruments/feature4.png';
import simplifiedCapTableBase from '../../assets/images/solutions/simplifiedCapTable/base.png';
import simplifiedCapTableFeature1 from '../../assets/images/solutions/simplifiedCapTable/feature1.png';
import simplifiedCapTableFeature2 from '../../assets/images/solutions/simplifiedCapTable/feature2.png';
import simplifiedCapTableFeature3 from '../../assets/images/solutions/simplifiedCapTable/feature3.png';
import simplifiedCapTableFeature4 from '../../assets/images/solutions/simplifiedCapTable/feature4.png';
import simplifiedCapTableFeature5 from '../../assets/images/solutions/simplifiedCapTable/feature5.png';

export type Feature = { title: string; text: string; img: string };

export type Solution = {
  title: string;
  description: string;
  img: string;
  benefits: [
    {
      title: string;
      description: string;
    },
    {
      title: string;
      description: string;
    },
    {
      title: string;
      description: string;
    },
  ];
  features: {
    title: string;
    description: string;
    list: Feature[];
  };
};

export enum SolutionVariant {
  EQUITY_PLAN_AUTOMATION = 'EQUITY_PLAN_AUTOMATION',
  OPTIMIZED_FUNDRAISING_INSTRUMENTS = 'OPTIMIZED_FUNDRAISING_INSTRUMENTS',
  SIMPLIFIED_CAP_TABLE = 'SIMPLIFIED_CAP_TABLE',
  ENHANCED_INVESTOR_RELATIONS = 'ENHANCED_INVESTOR_RELATIONS',
  EFFORTLESS_SCENARIO_MODELING = 'EFFORTLESS_SCENARIO_MODELING',
}

export const solutions: Record<SolutionVariant, Solution> = {
  [SolutionVariant.EQUITY_PLAN_AUTOMATION]: {
    description:
      'Employee ownership is the most powerful way to build trust, alignment and share long-term values. We help you create that seamless stakeholder experience so that you can focus on company growth.',
    benefits: [
      {
        description: 'Set up and manage grants in minutes with intuitive workflows.',
        title: 'Effortless Grant Creation',
      },
      {
        description: 'Eliminate errors with automated vesting schedules and equity calculations.',
        title: 'Automated Calculations',
      },
      {
        description: 'Streamline the grant acceptance process for a positive employee experience.',
        title: 'Easy Employee Onboarding',
      },
    ],
    features: {
      description:
        'Whether you’re setting up your first ESOP, or you’re managing employee ownership on a large scale, our automated and intuitive tools simplify it all. Automate vesting schedules, handle requests, and keep your team informed with clear, transparent equity insights.',
      list: [
        {
          img: equityPlanAutomationFeature1,
          text: 'Simplify and streamline your equity plan administration with our comprehensive management tools equipped to handle all international plan types: Stock Option, Common Stock, Warrants, RSU, RSA, Phantom, SAR and Hurdle.',
          title: 'Complete Plan Management',
        },
        {
          img: equityPlanAutomationFeature2,
          text: 'Effortlessly manage both time-based and performance-based vesting with our automated structures, ensuring precise and timely equity distribution.',
          title: 'Automated Vesting Structures ',
        },
        {
          img: equityPlanAutomationFeature3,
          text: 'Ensure transparency with automated reminders for critical dates – vesting schedules and exercise expirations – keeping both leaders and employees informed.',
          title: 'Comprehensive Reminders',
        },
        {
          img: equityPlanAutomationFeature4,
          text: 'Navigate the complexities of employee terminations with ease using our robust termination infrastructure, ensuring compliance and accuracy.',
          title: 'Termination Infrastructure',
        },
        {
          img: equityPlanAutomationFeature5,
          text: 'Boost employee motivation and retention by providing clear, accessible insights into their equity holdings and future potential.',
          title: 'Employee Engagement',
        },
      ],
      title: 'All the features you need, in one place',
    },
    img: equityPlanAutomationBase,
    title: 'Equity Plan Automation',
  },
  [SolutionVariant.OPTIMIZED_FUNDRAISING_INSTRUMENTS]: {
    benefits: [
      {
        title: 'Effortless Grant Creation',
        description: 'Set up and manage grants in minutes with intuitive workflows.',
      },
      {
        title: 'Automated Calculations',
        description: 'Eliminate errors with automated vesting schedules and equity calculations.',
      },
      {
        title: 'Easy Employee Onboarding',
        description: 'Streamline the grant acceptance process for a positive employee experience.',
      },
    ],
    description:
      'Fundraising doesn’t have to be overwhelming. Thanks to CapQuest and our comprehensive suite of financial tools, you can choose the one that suits your needs, simplify complex deals, and keep your capital structure optimized.',
    features: {
      description:
        'Our financial tools provide a robust solution for managing a diverse range of financial instruments. From equity and debt to convertible notes and SAFEs, we have you covered.',
      list: [
        {
          title: 'Share Class Management',
          text: ' With CapQuest, your most valuable asset is in safe hands: efficiently manage multiple share classes, track ownership changes, and calculate complex dividend distributions. Ensure accurate record-keeping and avoid costly errors.',
          img: optimizedFundraisingInstrumentsFeature1,
        },
        {
          img: optimizedFundraisingInstrumentsFeature2,
          text: 'Keep your SAFEs straightforward: set your terms, and we’ll track caps, discounts, and help you manage conversions with ease.',
          title: 'SAFE Management',
        },
        {
          img: optimizedFundraisingInstrumentsFeature3,
          text: 'Take the stress out of convertible debt: track interest accruals, manage and set your terms, and calculate conversions and their impact effortlessly.',
          title: 'Convertible Management',
        },
        {
          img: optimizedFundraisingInstrumentsFeature4,
          text: 'Keep venture debt under control: stay on top of repayment schedules, ensure compliance, and strengthen lender relationships.',
          title: 'Venture Debt Management',
        },
      ],
      title: 'Tools you need, clarity you want',
    },
    img: optimizedFundraisingInstrumentsBase,
    title: 'Optimized Fundraising Instruments',
  },
  [SolutionVariant.SIMPLIFIED_CAP_TABLE]: {
    description:
      'Your cap table is the foundation of your ownership structure. CapQuest gives you full visibility and control, simplifying complex scenarios, streamlining due diligence, and enabling smarter decisions.',
    benefits: [
      {
        title: 'Real-Time Data & Insights',
        description: 'Gain instant visibility into your cap table with clear visualizations.',
      },
      {
        title: 'Automated Calculations',
        description:
          'Eliminate errors with automated calculations for dilution and ownership percentages.',
      },
      {
        title: 'Drill-Down Capabilities',
        description: 'Get detailed insights into your cap table with a single click.',
      },
    ],
    features: {
      description: `Our intuitive cap table gives you a comprehensive view of your company's ownership, fully customizable to meet every organizational need. Easily track ownership changes, dilutions, and valuation history — all in one place.`,
      list: [
        {
          title: 'Ownership Breakdown',
          text: 'Easily visualize ownership broken down by stakeholder type or share class. Instantly see who owns what and get a clear picture of your company’s future equity structure at a glance.',
          img: simplifiedCapTableFeature1,
        },
        {
          title: 'Complete Transaction History',
          text: 'Track every transaction, from share issuances to transfers and buybacks, with a detailed and auditable history. Never lose sight of your equity movements.',
          img: simplifiedCapTableFeature2,
        },
        {
          title: 'Multi-Currency & Multi-Jurisdiction Support',
          text: 'No matter where you operate, handle your cap table with ease with support for multiple currencies and compliance with jurisdiction-specific rules.',
          img: simplifiedCapTableFeature3,
        },
        {
          title: 'Customized Views',
          text: 'Tailor your cap table to your priorities. From stakeholders to share classes or specific transactions, set the view that works for you.',
          img: simplifiedCapTableFeature4,
        },
        {
          title: 'Equity Insights Dashboard',
          text: 'See the big picture with dashboards that highlight ownership changes, dilution patterns, and top shareholders. Reporting has never been easier.',
          img: simplifiedCapTableFeature5,
        },
      ],
      title: 'A cap table built for simplicity and control',
    },
    img: simplifiedCapTableBase,
    title: 'Simplified Cap Table',
  },
  [SolutionVariant.ENHANCED_INVESTOR_RELATIONS]: {
    description:
      'Investor engagement drives success. CapQuest helps you build trust and strong relations through transparency and proactive communication.',
    benefits: [
      {
        title: 'Personalized Dashboards',
        description:
          'Empower investors with direct access to their equity information and holdings',
      },
      {
        title: 'Simplified Interface',
        description:
          'Easily understand ownership with clear visualizations and jargon-free explanations.',
      },
      {
        title: 'Actionable Insights',
        description:
          'Motivate investors with the ability to project the future value of their equity.',
      },
    ],
    features: {
      description:
        'Efficient investor communication matters — saving time and avoiding errors. That’s why we provide tools to keep investors engaged with personalized dashboards, timely metrics, and clear company updates — putting transparency at the core of what we do.',
      list: [
        {
          title: 'Personalized Dashboards',
          text: 'Offer investors instant access to financial performance, key metrics, and updates through personalized dashboards designed to keep them informed and engaged.',
          img: enhancedInvestorsRelationsFeature1,
        },
        {
          title: 'Fundraising & Exit Scenario Modeling',
          text: 'Give your investors the best tools to simulate ownership scenarios, evaluate outcomes, and make smarter, data-driven decisions.',
          img: enhancedInvestorsRelationsFeature2,
        },
        {
          title: 'Customizable MIS & KPI Reporting',
          text: 'Save time on reporting with CapQuest — automate customizable timely MIS and KPI reports and share them instantly with stakeholders.',
          img: enhancedInvestorsRelationsFeature3,
        },
        {
          title: 'Automated Updates',
          text: 'We keep track of the details and activities so that you don’t need to. Your activity centre keeps you updated on all investor requests and communications.',
          img: enhancedInvestorsRelationsFeature4,
        },
        {
          title: 'Investor Onboarding Tools',
          text: 'Make onboarding new investors effortless with secure document sharing and digital signatures for a smooth, professional start of your relationship.',
          img: enhancedInvestorsRelationsFeature5,
        },
      ],
      title: 'Inform & Empower',
    },
    img: enhancedInvestorsRelationsBase,
    title: 'Enhanced Investor Relations',
  },
  [SolutionVariant.EFFORTLESS_SCENARIO_MODELING]: {
    description:
      'Predicting the future is usually impossible. Not with CapQuest! Thanks to our enhanced scenario modelling, easily prepare and share simple to complex scenarios on the future of your company’s ownership structure.',
    features: {
      description:
        'We provide you with your own equity crystal ball to evaluate financial scenarios and dilution impacts of fundraising, exits, and debt — helping you shape the best strategy.',
      list: [
        {
          title: 'Precise Calculations, Instantly Shareable',
          text: ' Keep stakeholders in the loop by sharing scenarios that clearly explain valuation and dilution impacts.',
          img: effortlessScenarioModelingFeature1,
        },
        {
          title: 'Fundraising Round Scenario',
          text: 'Model different fundraising scenarios, compare them, assess the impact on ownership, trigger conversions — all in one place.',
          img: effortlessScenarioModelingFeature2,
        },
        {
          title: 'Exit Scenario',
          text: ' Visualize exit strategies — simulate IPOs, acquisitions, or sales to measure potential returns and exit multiples.',
          img: effortlessScenarioModelingFeature3,
        },
        {
          title: 'Debt & Venture Debt Scenario',
          text: ' Understand the financial effects of debt and venture debt while preparing optimized repayment plans.',
          img: effortlessScenarioModelingFeature4,
        },
      ],
      title: 'Predictability as a tool',
    },
    benefits: [
      {
        title: 'What-If Analysis Made Easy',
        description:
          'Run simulations to explore the impact of future funding rounds or exits on ownership.',
      },
      {
        title: 'Visualize Dilution',
        description:
          'Understand dilution effects making informed decisions about fundraising strategies easy',
      },
      {
        title: 'Customizable Scenarios',
        description:
          "Tailor your simulations to specific scenarios, ensuring you're prepared for various outcomes.",
      },
    ],
    img: effortlessScenarioModelingBase,
    title: 'Effortless Scenario Modeling',
  },
};
