import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../components/Combobox';
import { FormSchema, TopicTitle } from './validation';

type TopicItemsListProps = {
  control: Control<FormSchema>;
};

const TopicItemsList: FC<TopicItemsListProps> = ({ control }) => {
  return (
    <>
      {Object.entries(TopicTitle).map(([key, value]) => (
        <FormComboboxOption className="w-full" control={control} key={key} name="topic" value={key}>
          <span className="text-sm text-gray-700">{value}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type TopicComboboxProps = {
  control: Control<FormSchema>;
};

export const TopicCombobox: FC<TopicComboboxProps> = ({ control }) => {
  return (
    <FormCombobox className="relative w-full" control={control} name="topic">
      <div className="relative">
        <FormComboboxInput
          control={control}
          customValue={(value) => value && TopicTitle[value]}
          icon={<ChevronDownIcon />}
          name="topic"
          placeholder="Topic"
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
      </div>
      <ComboboxOptions className="absolute z-20 w-full overflow-x-hidden">
        <TopicItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};
