import React, { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';

type DropDownProps = {
  title: string;
  children: ReactNode;
  className?: string;
  onOpenTitleClassName?: string;
  icon?: ((isOPen: boolean) => ReactNode) | ReactNode;
  triggerOnIcon?: boolean;
};
export const DropDown: FC<DropDownProps> = ({
  title,
  children,
  className,
  onOpenTitleClassName,
  icon,
  triggerOnIcon,
}) => {
  const [isOpen, setOpen] = useState(false);

  const customIcon = typeof icon === 'function' ? icon?.(isOpen) : icon;
  return (
    <div className={twMerge('flex w-full flex-col px-2 py-4 transition-all', className)}>
      <div
        className="z-2 flex w-full cursor-pointer items-center justify-between"
        onClick={() => {
          !triggerOnIcon && setOpen((prev) => !prev);
        }}
      >
        <span
          className={twMerge(
            'text-sm font-[550] text-gray-900 transition-all',
            isOpen && onOpenTitleClassName,
          )}
        >
          {title}
        </span>
        <div
          className="h-fit w-fit p-1 transition-colors hover:bg-gray-50"
          onClick={() => {
            triggerOnIcon && setOpen((prev) => !prev);
          }}
        >
          {customIcon || (
            <ChevronDownIcon
              className={twMerge('size-4', isOpen ? 'rotate-180' : '', 'transition-transform')}
              iconColor={isOpen ? '#2565C8' : '#344054'}
            />
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'grid grid-flow-row transition-all duration-300',
          isOpen ? 'mt-4 grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className={twMerge('overflow-hidden')}>{children}</div>
      </div>
    </div>
  );
};
