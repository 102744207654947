import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';

const navigationMenuTriggerStyle = (className?: string) =>
  twMerge(
    'group inline-flex w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors',
    'hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none',
    'disabled:pointer-events-none disabled:opacity-50',
    'data-[active]:bg-accent/50 data-[state=open]:bg-accent/50',
    className,
  );

export const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    className={navigationMenuTriggerStyle(className)}
    ref={ref}
    {...props}
  >
    {children}{' '}
    <ChevronDownIcon
      aria-hidden="true"
      className="relative top-[1px] ml-1 h-16 w-3 transition duration-200 group-data-[state=open]:rotate-180"
    />
  </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;
