import {
  ComboboxInput as HeadlessComboboxInput,
  ComboboxInputProps as HeadlessInputProps,
} from '@headlessui/react';
import React, {
  ElementRef,
  forwardRef,
  HTMLInputTypeAttribute,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { RedInfoCircle } from '../../assets/icons';
import { useForwardRef } from '../../hooks/useForwardRef';

export enum InputStatus {
  IDLE_EMPTY = 'idle-empty',
  IDLE_FULL = 'idle-full',
  FOCUSED = 'focused',
  ERROR = 'error',
  SUCCESS = 'success',
}

const inputStatusToWrapperClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: 'border-b-[1px] border-gray-600',
  [InputStatus.IDLE_FULL]: 'border-b-[1px] border-gray-700',
  [InputStatus.FOCUSED]: 'border-b-[2px] border-brand-700',
  [InputStatus.ERROR]: 'border-b-[2px] border-fireside-600',
  [InputStatus.SUCCESS]: 'border-b-[2px] border-forest-500',
};

const inputStatusToPlaceholderClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: '!text-gray-400',
  [InputStatus.IDLE_FULL]: '!text-gray-500',
  [InputStatus.FOCUSED]: '!text-brand-700',
  [InputStatus.ERROR]: '!text-fireside-600',
  [InputStatus.SUCCESS]: '!text-forest-500',
};

export type ComboboxInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: ReactNode;
  type?: HTMLInputTypeAttribute;
  isError?: boolean;
  errorMessage?: false | string;
  isSuccess?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconBeforeInput?: ReactNode;
  onIconClick?: () => void;
  wrapperClassName?: false | string;
  customStatus?: InputStatus;
  readonly?: boolean;
  toUppercase?: boolean;
} & Omit<HeadlessInputProps, 'onChange' | 'ref' | 'placeholder'>;

const ComboboxInput = memo(
  forwardRef<ElementRef<typeof HeadlessComboboxInput>, ComboboxInputProps>(
    (
      {
        value,
        onChange,
        placeholder,
        type,
        isError,
        errorMessage,
        isSuccess,
        disabled,
        onFocus,
        onBlur,
        icon,
        iconBeforeInput,
        onIconClick,
        wrapperClassName,
        customStatus,
        readOnly,
        toUppercase,
        ...props
      },
      ref,
    ) => {
      const inputRef = useForwardRef<HTMLInputElement>(ref);
      const [isFocused, setIsFocused] = useState(false);
      const [status, setStatus] = useState(InputStatus.IDLE_EMPTY);

      useEffect(() => {
        if (isError || errorMessage) {
          setStatus(InputStatus.ERROR);
          return;
        }

        if (isSuccess) {
          setStatus(InputStatus.SUCCESS);
          return;
        }

        if (isFocused) {
          setStatus(InputStatus.FOCUSED);
          return;
        }

        setStatus(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
      }, [errorMessage, inputRef, isError, isFocused, isSuccess, value]);

      const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(false);
          onBlur?.(event);
        },
        [setIsFocused, onBlur],
      );

      const handleFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(true);
          onFocus?.(event);
        },
        [onFocus, setIsFocused],
      );

      return (
        <div className="flex flex-col gap-3">
          <div
            className={twMerge(
              `relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${inputStatusToWrapperClassNamesMap[customStatus ?? status]}`,
              wrapperClassName,
            )}
          >
            {placeholder && (
              <div
                className={`pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75 ${inputStatusToPlaceholderClassNamesMap[status]} ${isFocused || value ? '!top-[8px] !translate-y-0 !text-xs' : ''}`}
              >
                {placeholder}
              </div>
            )}
            {iconBeforeInput && iconBeforeInput}
            <div className="flex h-full w-full flex-col justify-end pb-2">
              <HeadlessComboboxInput
                {...props}
                className={twMerge(
                  'z-1 w-full bg-transparent font-inter text-sm font-[450] !text-gray-700 read-only:!text-gray-700 disabled:!text-gray-700',
                  disabled && '!text-gray-700',
                  toUppercase && 'uppercase',
                )}
                disabled={disabled}
                onBlur={handleBlur}
                onChange={(e) => onChange(e.target.value ?? '')}
                onFocus={handleFocus}
                readOnly={readOnly}
                ref={inputRef}
                type={type}
                value={value}
              />
            </div>
            {icon && (
              <div className={`${onIconClick ? 'cursor-pointer' : ''} `} onClick={onIconClick}>
                {icon}
              </div>
            )}
          </div>
          {errorMessage && (
            <div className="flex items-center gap-1">
              <RedInfoCircle />
              <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      );
    },
  ),
);

ComboboxInput.displayName = 'ComboboxInput';

export default ComboboxInput;
