import Intercom from '@intercom/messenger-js-sdk';
import React, { FC, ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  children: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
  const queryClient = new QueryClient();

  useEffect(() => {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_ID || '',
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">{children}</div>
      <ToastContainer closeOnClick position="bottom-center" />
    </QueryClientProvider>
  );
};

export default App;
