import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type UsersIconProps = IconProps;

const UsersIcon: FC<UsersIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_148_29789)">
        <path
          d="M11.3332 14V12.6667C11.3332 11.9594 11.0522 11.2811 10.5521 10.781C10.052 10.281 9.37375 10 8.6665 10H3.33317C2.62593 10 1.94765 10.281 1.44755 10.781C0.947456 11.2811 0.666504 11.9594 0.666504 12.6667V14M15.3332 14V12.6667C15.3327 12.0758 15.1361 11.5018 14.7741 11.0349C14.4121 10.5679 13.9053 10.2344 13.3332 10.0867M10.6665 2.08667C11.2401 2.23353 11.7485 2.56713 12.1116 3.03487C12.4747 3.50261 12.6717 4.07789 12.6717 4.67C12.6717 5.26211 12.4747 5.83739 12.1116 6.30513C11.7485 6.77287 11.2401 7.10647 10.6665 7.25333M8.6665 4.66667C8.6665 6.13943 7.4726 7.33333 5.99984 7.33333C4.52708 7.33333 3.33317 6.13943 3.33317 4.66667C3.33317 3.19391 4.52708 2 5.99984 2C7.4726 2 8.6665 3.19391 8.6665 4.66667Z"
          stroke={iconColor || '#101828'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.33333"
        />
      </g>
    </svg>
  );
};

export default UsersIcon;
