import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ContentIconProps = IconProps;

const ContentIcon: FC<ContentIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21859 10.6302V13.918M12.1639 13.918L9.56564 11.7528C9.08643 11.3534 8.84682 11.1537 8.57936 11.0774C8.34355 11.0102 8.09362 11.0102 7.85781 11.0774C7.59035 11.1537 7.35074 11.3534 6.87153 11.7528L4.27327 13.918M5.58838 7.34245V8M8.21859 6.02734V8M10.8488 4.71224V8M14.7941 2.08203H1.64307M2.30062 2.08203H14.1366V7.47396C14.1366 8.57875 14.1366 9.13114 13.9215 9.55312C13.7324 9.9243 13.4306 10.2261 13.0595 10.4152C12.6375 10.6302 12.0851 10.6302 10.9803 10.6302H5.45687C4.35208 10.6302 3.79968 10.6302 3.37771 10.4152C3.00653 10.2261 2.70475 9.9243 2.51562 9.55312C2.30062 9.13114 2.30062 8.57875 2.30062 7.47396V2.08203Z"
        stroke={iconColor || '#98A2B3'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.18359"
      />
    </svg>
  );
};

export default ContentIcon;
