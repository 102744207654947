import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type AngleProps = IconProps;

const Angle: FC<AngleProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="8"
      viewBox="0 0 9 8"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill={iconColor || '#2565C8'}
        height="2"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 0.0517578 0)"
        width="8"
      />
      <rect
        fill={iconColor || '#2565C8'}
        height="2"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 2.05176 8)"
        width="6"
      />
    </svg>
  );
};

export default Angle;
