import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CalculatorIconProps = IconProps;

const CalculatorIcon: FC<CalculatorIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1167 4.14852L4.18318 11.0821M5.44383 6.66981V4.14852M4.18318 5.40916H6.70448M8.59545 9.82143H11.1167M5.00261 13.2882H10.2973C11.3564 13.2882 11.8859 13.2882 12.2904 13.0821C12.6462 12.9008 12.9355 12.6115 13.1168 12.2557C13.3229 11.8512 13.3229 11.3217 13.3229 10.2627V4.96794C13.3229 3.90889 13.3229 3.37937 13.1168 2.97487C12.9355 2.61906 12.6462 2.32978 12.2904 2.14849C11.8859 1.94238 11.3564 1.94238 10.2973 1.94238H5.00261C3.94356 1.94238 3.41404 1.94238 3.00954 2.14849C2.65373 2.32978 2.36445 2.61906 2.18315 2.97487C1.97705 3.37937 1.97705 3.90889 1.97705 4.96794V10.2627C1.97705 11.3217 1.97705 11.8512 2.18315 12.2557C2.36445 12.6115 2.65373 12.9008 3.00954 13.0821C3.41404 13.2882 3.94356 13.2882 5.00261 13.2882Z"
        stroke={iconColor || '#344054'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.983306"
      />
    </svg>
  );
};

export default CalculatorIcon;
