import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  AlarmClockIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  CheckVerifiedIcon,
  DataFlowIcon,
} from '../../assets/icons';
import blueDots from '../../assets/images/background/blue-dots.png';
import bottomBlueDots from '../../assets/images/background/bottom-blue-dots.png';
import { SolutionVariant } from '../../pages/Solutions/types';
import Button from '../Button';
import GradientBorderBox from '../GradientBorderBox/GradientBorderBox';

export type TabProps = {
  title: string;
  subtitle: string;
  children: ReactNode;
  points: string[];
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  className?: string;
  navigateState: SolutionVariant;
};

export const Tab: React.FC<TabProps> = ({
  title,
  subtitle,
  children,
  points,
  handleMouseEnter,
  handleMouseLeave,
  className,
  navigateState,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="relative z-10 flex min-h-[443px] w-full max-w-[1280px] flex-col items-center gap-6 rounded-lg bg-[#F4FCFF] lg:flex-row lg:items-end lg:gap-4">
        <div className="right-[157px] top-0 hidden h-[460px] w-[502px] rounded-full bg-[#2565C8] opacity-40 blur-[368px] lg:absolute" />
        <div
          className="right-0 top-0 hidden h-[147px] w-[683px] bg-no-repeat lg:absolute"
          style={{
            backgroundImage: `url(${blueDots})`,
          }}
        />
        <div
          className="bottom-0 right-0 hidden h-[147px] w-[635px] bg-no-repeat lg:absolute"
          style={{
            backgroundImage: `url(${bottomBlueDots})`,
          }}
        />
        <div className="relative z-10 flex min-w-[280px] max-w-[362px] flex-col items-start justify-start gap-8 px-4 py-12 text-start lg:max-w-[605px] lg:pl-14">
          <span className="text-[24px] font-semibold leading-[1.2] text-gray-900 xlg:text-[32px]">
            {title}
          </span>
          <span className="text-[16px] leading-[1.5] text-gray-900">{subtitle}</span>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <CheckVerifiedIcon />
              <span className="text-[15px] leading-[1.6] text-gray-900">{points[0]}</span>
            </div>
            <div className="flex items-center gap-2">
              <CheckCircleIcon />
              <span className="text-[15px] leading-[1.6] text-gray-900">{points[1]}</span>
            </div>
            <div className="flex items-center gap-2">
              <DataFlowIcon />
              <span className="text-[15px] leading-[1.6] text-gray-900">{points[2]}</span>
            </div>
            <div className="flex items-center gap-2">
              <AlarmClockIcon />
              <span className="text-[15px] leading-[1.6] text-gray-900">{points[3]}</span>
            </div>
          </div>

          <Button
            className="group relative block text-[15px] font-[500] leading-[1.33] text-brand-700"
            onClick={() => {
              navigate('/solutions', { state: navigateState });
              window.scrollTo(0, 0);
            }}
            styleType="NONE"
          >
            <div className="flex items-center gap-[6px]">
              <span>Learn more</span>
              <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2">
                <ArrowRightIcon iconColor="#2565c8" />
              </span>
            </div>
          </Button>
        </div>
        <div
          className={twMerge(
            'relative z-10 ml-auto mr-auto flex h-[293px] w-[329px] pr-0 lg:min-h-[400px] lg:w-full lg:pr-[88px]',
            className,
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
        <GradientBorderBox variant="TOOLS" />
      </div>
    </div>
  );
};
