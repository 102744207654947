import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    className={twMerge('group flex flex-1 list-none items-center justify-center', className)}
    ref={ref}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;
