import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type MenuIconProps = IconProps;

const MenuIcon: FC<MenuIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12H21M3 6H21M3 18H21"
        stroke={iconColor || '#344054'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default MenuIcon;
