import { z } from 'zod';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export enum Topic {
  BOOK_DEMO = 'book-demo',
  GENERAL = 'general',
  PERKS = 'perks',
  TECH_HELP = 'tech-help',
}

export const TopicTitle: Record<Topic, string> = {
  [Topic.BOOK_DEMO]: 'Book a Demo',
  [Topic.GENERAL]: 'General Enquiry',
  [Topic.PERKS]: 'Perks & Partnerships',
  [Topic.TECH_HELP]: 'Technical Help',
};

export const formSchema = z
  .object({
    firstName: z.string().trim().min(1, 'Required'),
    lastName: z.string().trim().min(1, 'Required'),
    email: z.string().email(),
    phoneCountry: z
      .object({
        iso2Code: z.string(),
        name: z.string(),
        prefix: z.string(),
      })
      .optional(),
    phoneNumber: z.string().regex(phoneRegex, 'Invalid Number!').or(z.literal('')).default(''),
    companyName: z.string().trim().min(1, 'Required'),
    topic: z.nativeEnum(Topic),
    message: z.string().trim().min(1, 'Required'),
  })
  .refine(() => {
    return true;
  });

export type FormSchema = z.infer<typeof formSchema>;
