import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CoinsStackedIconProps = IconProps;

const CoinsStackedIcon: FC<CoinsStackedIconProps> = ({ className, iconColor }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5005 17C12.5005 19.7614 14.7391 22 17.5005 22C20.2619 22 22.5005 19.7614 22.5005 17C22.5005 14.2386 20.2619 12 17.5005 12C14.7391 12 12.5005 14.2386 12.5005 17ZM12.5005 17C12.5005 15.8742 12.8726 14.8353 13.5005 13.9995V5M12.5005 17C12.5005 17.8254 12.7005 18.604 13.0546 19.2901C12.2122 20.0018 10.2663 20.5 8.00049 20.5C4.96292 20.5 2.50049 19.6046 2.50049 18.5V5M13.5005 5C13.5005 6.10457 11.0381 7 8.00049 7C4.96292 7 2.50049 6.10457 2.50049 5M13.5005 5C13.5005 3.89543 11.0381 3 8.00049 3C4.96292 3 2.50049 3.89543 2.50049 5M2.50049 14C2.50049 15.1046 4.96292 16 8.00049 16C10.1895 16 12.0798 15.535 12.9651 14.8618M13.5005 9.5C13.5005 10.6046 11.0381 11.5 8.00049 11.5C4.96292 11.5 2.50049 10.6046 2.50049 9.5"
        stroke={iconColor || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CoinsStackedIcon;
