import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CalculatorIcon, StarsIcon, TickIcon } from '../../assets/icons';
import cubesImg from '../../assets/images/about/cubes.png';
import dubaiImg from '../../assets/images/about/dubai.png';
import equityImg from '../../assets/images/about/equity.png';
import lighthouseImg from '../../assets/images/about/lighthouse.png';
import parisImg from '../../assets/images/about/paris.png';
import storyImg from '../../assets/images/about/story.png';
// import CompanySlider from '../../components/CompanySlider/CompanySlider';
import FinalSection from '../../components/FinalSection/FinalSection';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

export const About: FC = () => {
  return (
    <div className="relative text-center font-inter text-sm font-normal text-gray-700">
      <div
        className="relative flex h-fit min-h-full w-full flex-col items-center overflow-hidden"
        style={{ background: 'linear-gradient(180deg, #FFFAEB 0%, #FFFFFF 100%)' }}
      >
        <div className="absolute -left-[271px] -top-[248px] h-[496px] w-[542px] rounded-full bg-[#F79009] opacity-100 blur-[368px]" />
        <div className="absolute -bottom-[312px] -right-[318px] hidden h-[624px] w-[636px] rounded-full bg-[#F79009] opacity-40 blur-[476px] xlg:block" />
        <div className="absolute -bottom-[16px] -right-[300px] h-[416px] w-[408px] rounded-full bg-[#F79009] opacity-40 blur-[476px] xlg:hidden" />
        <Header className="bg-transparent" />
        <section className="relative ml-auto mr-auto flex w-full max-w-[1280px] flex-col items-center gap-10 px-4 pb-[84px] pt-[88px] xlg:gap-[84px]">
          <div className="flex max-w-[637px] flex-col items-center gap-4 text-center">
            <span className="rounded-[32px] border-[1px] border-gray-400 px-3 py-[3px] text-label-md font-medium text-gray-500">
              CapQuest
            </span>
            <h2 className="text-[48px] font-semibold leading-[1] text-gray-900 xlg:text-xxl">
              About us
            </h2>
            <span className="pb-1 pt-2 text-base text-gray-600">
              At CapQuest, we understand quite well how overwhelming the equity journey of a company
              can be! We’re here to make equity management a breeze for everyone — small startups to
              big businesses — giving founders, teams, and investors the clarity and tools they need
              to stay in control and make smarter decisions.
            </span>
          </div>

          <div className="flex w-full flex-col rounded-lg border-[1px] border-gray-200 bg-white xlg:flex-row">
            <div className="flex flex-grow basis-[50%] flex-col gap-8 px-6 py-8 xlg:p-10 xlg:text-left lg:p-14">
              <span className="text-[18px] font-semibold leading-[1.2] text-gray-900 xlg:text-[32px]">
                Our Story
              </span>
              <span className="text-base text-gray-900">
                We’re three repeat founders who’ve been through the ups and downs of
                Entrepreneurship: scaling fast, fundraising hard, and managing investor relations.
                We know first-hand how investor relations and equity management require expertise
                and eat into time you’d rather spend growing your business.
              </span>
              <span className="text-base text-gray-900">
                Through our experience across different markets and industries, we’ve seen the same
                headaches over and over — ownership structures and keeping investors informed.
                That’s why we’re fired up to make equity management simple and stress-free.
              </span>
              <span className="text-base text-gray-900">
                We want founders, team members, employees and investors to feel empowered, informed,
                and in control as they grow together.
              </span>
            </div>
            <div className="flex-grow basis-[50%]">
              <div
                className="h-[286px] w-full rounded-b-lg bg-cover bg-center bg-no-repeat xlg:h-full xlg:rounded-r-lg xlg:rounded-bl-none lg:min-h-[567px]"
                style={{
                  backgroundImage: `url(${storyImg})`,
                }}
              />
            </div>
          </div>

          <div className="flex max-w-[654px] flex-col items-center gap-4 text-center">
            <h2 className="text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:text-3xl">
              What we believe
            </h2>
            <span className="pb-1 pt-2 text-base text-gray-600">
              CapQuest is built on the idea of democratizing ownership — making equity accessible,
              clear, and empowering for all. We give stakeholders the insights and tools to unlock
              their ownership’s true potential.
            </span>
          </div>

          <div className="flex w-full flex-col gap-10 xlg:gap-8">
            <div className="flex w-full flex-col-reverse rounded-lg border-[1px] border-gray-200 bg-white xlg:flex-row">
              <div className="basis-[50%]">
                <div
                  className="h-[286px] w-full rounded-b-lg bg-cover bg-center bg-no-repeat xlg:h-full xlg:rounded-l-lg xlg:rounded-br-none lg:min-h-[567px]"
                  style={{
                    backgroundImage: `url(${equityImg})`,
                  }}
                />
              </div>
              <div className="flex basis-[50%] flex-col gap-8 px-6 py-8 xlg:p-10 lg:p-14">
                <span className="text-[18px] font-semibold leading-[1.2] text-gray-900 xlg:text-right xlg:text-[32px]">
                  Equity should be easily understood
                </span>
                <span className="text-base text-gray-900 xlg:text-right">
                  Navigating cap tables, ESOPs, share classes, vesting structures can quickly get
                  overwhelming.
                </span>
                <span className="text-base text-gray-900 xlg:text-right">
                  We’re here to simplify things with powerful tools and resources, giving
                  stakeholders clarity throughout their equity journey and founders more control
                  over the management of their most valuable asset.
                </span>
              </div>
            </div>

            <div className="flex w-full flex-col rounded-lg border-[1px] border-gray-200 bg-white xlg:flex-row">
              <div className="flex flex-grow basis-[50%] flex-col gap-8 px-6 py-8 xlg:p-10 xlg:text-left lg:p-14">
                <span className="text-[18px] font-semibold leading-[1.2] text-gray-900 xlg:text-[32px]">
                  Trust is built through transparency
                </span>
                <span className="text-base text-gray-900">
                  We believe that ownership and equity management shouldn’t be reserved for experts.
                  Everyone should be able to navigate it and understand its benefits. We built
                  CapQuest with a single idea in mind: making it simple, clear and accessible. From
                  setting up equity plans and tracking vesting to automating updates, we keep
                  stakeholders informed at the heart of company growth.
                </span>
                <span className="text-base text-gray-900">
                  With CapQuest, transparency isn’t a perk; it’s how we help everyone grow together.
                </span>
              </div>
              <div className="flex-grow basis-[50%]">
                <div
                  className="h-[286px] w-full rounded-b-lg bg-cover bg-center bg-no-repeat xlg:h-full xlg:rounded-r-lg xlg:rounded-bl-none lg:min-h-[567px]"
                  style={{
                    backgroundImage: `url(${lighthouseImg})`,
                  }}
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-10 xlg:gap-8">
              <div className="flex w-full flex-col-reverse rounded-lg border-[1px] border-gray-200 bg-white xlg:flex-row">
                <div className="basis-[50%]">
                  <div
                    className="h-[286px] w-full rounded-b-lg bg-cover bg-center bg-no-repeat xlg:h-full xlg:rounded-l-lg xlg:rounded-br-none lg:min-h-[567px]"
                    style={{
                      backgroundImage: `url(${cubesImg})`,
                    }}
                  />
                </div>
                <div className="flex basis-[50%] flex-col gap-8 px-6 py-8 xlg:p-10 lg:p-14">
                  <span className="text-[18px] font-semibold leading-[1.2] text-gray-900 xlg:text-right xlg:text-[32px]">
                    As companies grow, demands change
                  </span>
                  <span className="text-base text-gray-900 xlg:text-right">
                    Growth brings new challenges.
                  </span>
                  <span className="text-base text-gray-900 xlg:text-right">
                    From onboarding your first investor, converting your SAFE holders to managing
                    complex venture debt clauses, CapQuest is your partner at every stage. Our tools
                    evolve as you do, keeping you ready for today and prepared for tomorrow.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex max-w-[654px] flex-col items-center gap-4 text-center">
            <h2 className="text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:text-3xl">
              We are here to help
            </h2>
            <span className="text-base text-gray-600">
              Not sure where to start? Need help in finding the right equity plan to reward your
              team or the best fundraising tool? Or maybe you just want to have a chat with someone
              who gets it? Reach out! We’re here to help! Really.
            </span>
          </div>
        </section>
      </div>

      {/* <section className="flex w-full flex-col items-center gap-12 border-y-[1px] border-[#1018280F] py-12 xlg:py-14">
        <span className="text-base text-gray-600">
          Trusted by leading companies and partners from around the world
        </span>
        <CompanySlider />
      </section> */}

      <section className="flex w-full flex-col items-center gap-10 px-4 pb-14 pt-10 xlg:gap-[84px] xlg:px-10 xlg:py-[84px]">
        <h2 className="text-[32px] font-semibold leading-[1.25] text-gray-900 xlg:text-3xl">
          Office locations
        </h2>
        <div className="flex w-full max-w-[1030px] flex-col items-center px-4">
          <div className="flex w-full flex-col items-center gap-10 xlg:flex-row xlg:items-start xlg:gap-6">
            <div className="flex w-full flex-col items-center gap-6">
              <div
                className="size-[361px] rounded-t-lg bg-cover bg-center bg-no-repeat xlg:h-[487px] xlg:w-full xlg:rounded-tr-none"
                style={{
                  backgroundImage: `url(${dubaiImg})`,
                }}
              />
              <span className="text-center text-base text-gray-900">
                Dubai International Financial Centre <br />
                Innovation Hub, Gate Avenue Level 1, <br />
                Dubai, United Arab Emirates
              </span>
            </div>

            <div className="flex w-full flex-col items-center gap-6">
              <div
                className="size-[361px] rounded-t-lg bg-cover bg-center bg-no-repeat xlg:h-[487px] xlg:w-full xlg:rounded-tl-none"
                style={{
                  backgroundImage: `url(${parisImg})`,
                }}
              />
              <span className="text-center text-base text-gray-900">
                Station F, Landing Zone <br />
                5 Parvis Alan-Turing <br />
                75013, Paris, France
              </span>
            </div>
          </div>
          <span className="mt-14 w-full rounded-lg bg-brand-25 py-4 text-center text-[18px] font-medium leading-[1.6] text-gray-700">
            But you can always say hi here:{' '}
            <Link className="font-semibold text-brand-700 underline" to={'/contact-sales'}>
              Contact Us
            </Link>
          </span>
        </div>
      </section>

      <section className="relative flex w-full flex-col gap-16 overflow-hidden bg-[#020811] px-4 py-12 xlg:py-[72px] lg:py-[84px]">
        <div className="absolute -top-[288px] left-[50%] h-[496px] w-[542px] -translate-x-[50%] rounded-full bg-[#2565C8] opacity-50 blur-[368px]" />

        <div className="flex w-full flex-col items-center gap-4">
          <h2 className="max-w-[687px] text-[32px] font-semibold leading-[1.25] text-white xlg:text-3xl">
            Why you’ll love CapQuest
          </h2>
          <span className="max-w-[552px] text-center text-base text-[#C6C6C6]">
            Join the growing number of companies who trust us to make ESOPs, Cap Tables and
            ownership management a breeze.
          </span>
        </div>
        <div className="flex flex-col justify-center gap-4 xlg:flex-row">
          <div className="flex basis-[33%] flex-col items-center justify-center gap-4 rounded-xl bg-[#1D2939] px-5 py-8 xlg:max-w-[340px]">
            <TickIcon />
            <span className="pt-4 text-center text-l font-semibold text-white">
              Transparent Investor Relations
            </span>
            <span className="text-center text-sm text-[#C6C6C6]">
              Keep your investors engaged with clear, ongoing communication.
            </span>
          </div>
          <div className="flex basis-[33%] flex-col items-center justify-center gap-4 rounded-xl bg-[#1D2939] px-5 py-8 xlg:max-w-[340px]">
            <CalculatorIcon className="size-14" iconColor="white" />
            <span className="pt-4 text-center text-l font-semibold text-white">
              Automated Calculations
            </span>
            <span className="text-center text-sm text-[#C6C6C6]">
              Eliminate errors with automated vesting and equity tools.
            </span>
          </div>
          <div className="flex basis-[33%] flex-col items-center justify-center gap-4 rounded-xl bg-[#1D2939] px-5 py-8 xlg:max-w-[340px]">
            <StarsIcon className="size-14" iconColor="#ffffff" />
            <span className="pt-4 text-center text-l font-semibold text-white">
              Democratize employee ownership
            </span>
            <span className="text-center text-sm text-[#C6C6C6]">
              Offer seamless ownership access to your employees and team members.
            </span>
          </div>
        </div>
      </section>
      <FinalSection />
      <Footer />
    </div>
  );
};
